<template>
  <v-container fill-height>
    <v-row>
      <v-col>
        <v-card class="mb-6">
          <v-card-title> Raumparameter </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- Slider für Länge x -->
            <v-slider
              label="Länge x"
              v-model="geometryLengthX"
              v-bind:min="lengthSliderDefault.min.x"
              v-bind:max="lengthSliderDefault.max.x"
              v-bind:step="lengthSliderDefault.step.x"
              validate-on-blur
            >
              <template v-slot:prepend>
                <v-icon>mdi-axis-x-arrow</v-icon>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="geometryLengthX"
                  v-bind:min="lengthSliderDefault.min.x"
                  v-bind:max="lengthSliderDefault.max.x"
                  v-bind:step="lengthSliderDefault.step.x"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 70px"
                  suffix=" m"
                ></v-text-field>
              </template>
            </v-slider>
            <!-- Slider für Breite y -->
            <v-slider
              label="Breite y"
              v-model="geometryLengthY"
              v-bind:min="lengthSliderDefault.min.y"
              v-bind:max="lengthSliderDefault.max.y"
              v-bind:step="lengthSliderDefault.step.y"
              validate-on-blur
            >
              <template v-slot:prepend>
                <v-icon>mdi-axis-y-arrow</v-icon>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="geometryLengthY"
                  v-bind:min="lengthSliderDefault.min.y"
                  v-bind:max="lengthSliderDefault.max.y"
                  v-bind:step="lengthSliderDefault.step.y"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 70px"
                  suffix=" m"
                ></v-text-field>
              </template>
            </v-slider>
            <!-- Slider für Höhe z -->
            <v-slider
              label="Höhe z"
              v-model="geometryLengthZ"
              v-bind:min="lengthSliderDefault.min.z"
              v-bind:max="lengthSliderDefault.max.z"
              v-bind:step="lengthSliderDefault.step.z"
              validate-on-blur
            >
              <template v-slot:prepend>
                <v-icon>mdi-axis-z-arrow</v-icon>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="geometryLengthZ"
                  v-bind:min="lengthSliderDefault.min.z"
                  v-bind:max="lengthSliderDefault.max.z"
                  v-bind:step="lengthSliderDefault.step.z"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 70px"
                  suffix=" m"
                ></v-text-field>
              </template>
            </v-slider>
            <v-divider></v-divider>
            <!-- Slider für Deckkraft -->
            <v-slider
              label="Deckkraft"
              class="pt-4"
              v-model="opacityPercent"
              v-bind:min="opacitySliderDefault.min"
              v-bind:max="opacitySliderDefault.max"
              v-bind:step="opacitySliderDefault.step"
              validate-on-blur
            >
              <template v-slot:prepend>
                <v-icon>mdi-opacity</v-icon>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="opacityPercent"
                  v-bind:min="opacitySliderDefault.min"
                  v-bind:max="opacitySliderDefault.max"
                  v-bind:step="opacitySliderDefault.step"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 70px"
                  suffix=" %"
                ></v-text-field>
              </template>
            </v-slider>
            <v-divider></v-divider>
            <!-- Controls für Schützenpositionen -->
            <v-switch
              label="Schützenpositionen anzeigen"
              v-model="showShooterPositions"
            >
              <template v-slot:prepend>
                <v-icon>mdi-arrow-expand-right</v-icon>
              </template>
            </v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <!-- Karte für 3D-Ansicht -->
        <v-card class="mb-6">
          <v-card-title> 3D-Ansicht </v-card-title>
          <v-divider></v-divider>
          <RoomPlot
            :uuid="roomPlotID"
            :geometry="geometry"
            :position="position"
            :showFace="null"
            :showShooterPositions="showShooterPositions"
          />
        </v-card>
      </v-col>
      <!-- Floating Action Button -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            to="/dashboard"
            bottom
            right
            fixed
            class="mb-8 mr-8"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon x-large>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>Dashboard</span>
      </v-tooltip>
    </v-row>

    <v-snackbar v-model="isAlertOpen" timeout="5000"
      >Funktion noch nicht implementiert.
      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="isAlertOpen = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import RoomPlot from "../components/plots/RoomPlot.vue";
import * as util from "@/lib/util/index.js";

export default {
  name: "Geometrie",
  components: {
    RoomPlot,
  },
  data: () => ({
    geometry: null,
    position: null,
    roomPlotID: 2001,

    isAlertOpen: false,
    showShooterPositions: false,

    lengthSliderDefault: {
      min: {
        x: 30,
        y: 5,
        z: 2,
      },
      max: {
        x: 60,
        y: 30,
        z: 6.0,
      },
      step: {
        x: 0.1,
        y: 0.1,
        z: 0.1,
      },
    },
    opacitySliderDefault: {
      min: 10,
      max: 100,
      step: 1,
    },
  }),
  created() {
    this.geometry = this.getGeometry;
    this.position = this.getPosition;

    this.$bus.$on("contextEvent", ($contextPayload) => {
      if ($contextPayload == "showAlert") {
        this.isAlertOpen = true;
      }
    });
  },
  computed: {
    ...mapGetters(["getGeometry", "getPosition"]),

    opacityPercent: {
      get: function () {
        return Math.trunc(this.geometry.opacity * 100);
      },
      set: function (value) {
        this.geometry.opacity = value / 100;
      },
    },

    geometryLengthX: {
      get: function () {
        return this.geometry.length.x;
      },
      set: function (value) {
        this.geometry.length.x = value;

        let xPositionsShooter = util.getShooterXPositions(value);
        for (let i = 0; i < this.position.shooter.length; i++) {
          this.position.shooter[i].x = xPositionsShooter[i];
        }

        let xPositionsRating = util.getRatingXPositions(value);
        for (let i = 0; i < this.position.rating.length; i++) {
          this.position.rating[i].x = xPositionsRating[i];
        }
      },
    },

    geometryLengthY: {
      get: function () {
        return this.geometry.length.y;
      },
      set: function (value) {
        this.geometry.length.y = value;

        let yPositionsShooter = util.getShooterYPositions(value);
        for (let i = 0; i < this.position.shooter.length; i++) {
          this.position.shooter[i].y = yPositionsShooter[i];
        }

        let yPositionsRating = util.getRatingYPositions(value);
        for (let i = 0; i < this.position.rating.length; i++) {
          this.position.rating[i].y = yPositionsRating[i];
        }
      },
    },

    geometryLengthZ: {
      get: function () {
        return this.geometry.length.z;
      },
      set: function (value) {
        this.geometry.length.z = value;
      },
    },
  },
  methods: {
    emitContextEvent(contextPayload) {
      this.$bus.$emit("contextEvent", contextPayload);
    },
  },
  params: {
    // Legt Titel und Einträge im Kontextmenu fest
    menu: [
      { title: "Geometrie Menu", subtitle: "Subtitle" },
      {
        entry: [
          {
            name: "Geometrie zurücksetzen",
            icon: "mdi-restore",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Geometrie speichern",
            icon: "mdi-content-save",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Geometrie laden",
            icon: "mdi-cloud-upload",
            contextHandle: "showAlert",
            divider: false,
          },
        ],
      },
    ],
  },
};
</script>
