/**
 * Cervus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Audio from './Audio';

/**
 * The ChaserOutput model module.
 * @module model/ChaserOutput
 * @version 1.0.0
 */
class ChaserOutput {
    /**
     * Constructs a new <code>ChaserOutput</code>.
     * output values / result of a single chaser calculation
     * @alias module:model/ChaserOutput
     */
    constructor() { 
        
        ChaserOutput.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ChaserOutput</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ChaserOutput} obj Optional instance to populate.
     * @return {module:model/ChaserOutput} The populated <code>ChaserOutput</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChaserOutput();

            if (data.hasOwnProperty('source_signal')) {
                obj['source_signal'] = Audio.constructFromObject(data['source_signal']);
            }
            if (data.hasOwnProperty('receiver_signal')) {
                obj['receiver_signal'] = Audio.constructFromObject(data['receiver_signal']);
            }
            if (data.hasOwnProperty('reference_signal')) {
                obj['reference_signal'] = Audio.constructFromObject(data['reference_signal']);
            }
            if (data.hasOwnProperty('quality_number_ahaah')) {
                obj['quality_number_ahaah'] = ApiClient.convertToType(data['quality_number_ahaah'], 'Number');
            }
            if (data.hasOwnProperty('quality_number_pfander')) {
                obj['quality_number_pfander'] = ApiClient.convertToType(data['quality_number_pfander'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/Audio} source_signal
 */
ChaserOutput.prototype['source_signal'] = undefined;

/**
 * @member {module:model/Audio} receiver_signal
 */
ChaserOutput.prototype['receiver_signal'] = undefined;

/**
 * @member {module:model/Audio} reference_signal
 */
ChaserOutput.prototype['reference_signal'] = undefined;

/**
 * @member {Number} quality_number_ahaah
 */
ChaserOutput.prototype['quality_number_ahaah'] = undefined;

/**
 * @member {Number} quality_number_pfander
 */
ChaserOutput.prototype['quality_number_pfander'] = undefined;






export default ChaserOutput;

