

export default {
  blue: {
    p100: "#2E406B",
    p75: "#627090",
    p50: "#969FB5",
    p25: "#CBCFDA",
    p10: "#EAECF0",
  },
  green: {
    p100: "#638F38",
    p75: "#8AAB6A",
    p50: "#B1C79B",
    p25: "#D8E3CD",
    p10: "#EFF4EB",
  },
  yellow: {
    p100: "#F7D417",
    p75: "#F9DF51",
    p50: "#FBE98B",
    p25: "#FDF4C5",
    p10: "#FEFBE8",
  },
  red: {
    p100: "#D42E12",
    p75: "#DF624D",
    p50: "#E99688",
    p25: "#F4CBC4",
    p10: "#FBEAE7",      
  },
  cyan: {
    p100: "#578AD6",
    p75: "#81A7E0",
    p50: "#ABC4EA",
    p25: "#D5E2F5",
    p10: "#EEF3FB",
  },
  class: {
    a: '#006837',
    b: '#66BE63',
    c: '#DAF08C',
    d: '#FFFF8C',
    e: '#F56D43',
    f: '#A60026',
  }
};
