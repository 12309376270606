var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',_vm._b({ref:_vm.uuid},'div',(_vm.plotData, _vm.plotLayout),false)),_c('v-speed-dial',{attrs:{"bottom":"","right":"","absolute":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.isFabOpen)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","fab":"","small":""},model:{value:(_vm.isFabOpen),callback:function ($$v) {_vm.isFabOpen=$$v},expression:"isFabOpen"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,false,3404847294)},[_c('span',[_vm._v("Schließen")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","fab":"","small":""},model:{value:(_vm.isFabOpen),callback:function ($$v) {_vm.isFabOpen=$$v},expression:"isFabOpen"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Optionen")])])]},proxy:true}]),model:{value:(_vm.isFabOpen),callback:function ($$v) {_vm.isFabOpen=$$v},expression:"isFabOpen"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"accent"},on:{"click":_vm.downloadImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera")])],1)]}}])},[_c('span',[_vm._v("Herunterladen")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }