  <template>
  <v-container>
    <v-row>
      <!-- Linke Spalte -->
      <v-col>
        <!-- Karte für 3D-Ansicht -->
        <RoomPlotCard
          :uuid="this.roomPlotID"
          :geometry="this.geometry"
          :isCardFoldable="true"
        />

        <!-- Karte für Raumparameter -->
        <GeometryCard :geometry="this.geometry" />

        <!-- Karte für Materialparameter -->
        <MaterialCard
          :materials="this.materials"
          :facesOfCube="this.facesOfCube"
        />

        <!-- Karte für Szenario -->
        <ScenarioCard :position="this.position" :weapon="this.weapon" />
      </v-col>

      <!-- Rechte Spalte -->
      <v-col>
        <!-- Karte für Quellsignal -->
        <TimeSignalCard
          :uuid="this.timePlotID"
          :signalTime="this.signalTime"
          :sourceSignalValue="this.sourceSignalValue"
          :receiverSignalValue="this.receiverSignalValue"
          :showOverlay="this.isBackendBusy"
        />

        <!-- Karte für Qualitätszahlen -->
        <QualityNumberCard
          :uuid="this.gaugePlotID"
          :qualityNumbers="this.qualityNumbers"
          :showOverlay="this.isBackendBusy"
        />
      </v-col>

      <!-- Floating Action Button -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            bottom
            right
            fixed
            class="mb-8 mr-8"
            v-bind="attrs"
            v-on="on"
            @click="emitContextEvent('callApi')"
          >
            <v-icon x-large>mdi-play</v-icon>
          </v-btn>
        </template>
        <span>Berechnen</span>
      </v-tooltip>
    </v-row>

    <!-- Alert für nicht-implementierte Funktionen -->
    <v-snackbar v-model="isAlertOpen" timeout="5000"
      >Funktion noch nicht implementiert.
      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="isAlertOpen = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import RoomPlotCard from "../components/cards/RoomPlotCard.vue";
import GeometryCard from "../components/cards/GeometryCard.vue";
import MaterialCard from "../components/cards/MaterialCard.vue";
import ScenarioCard from "../components/cards/ScenarioCard.vue";
import TimeSignalCard from "../components/cards/TimeSignalCard.vue";
import QualityNumberCard from "../components/cards/QualityNumbersCard.vue";
import { getAccessToken } from "@/lib/auth/index.js";
import * as geo from "@/lib/geometry/index.js";
import * as util from "@/lib/util/index.js";
import ApiClient from "@/lib/api/src/ApiClient.js";
import ChaserInput from "@/lib/api/src/model/ChaserInput.js";
import Material from "@/lib/api/src/model/Material.js";
import Point3d from "@/lib/api/src/model/Point3d.js";
import Triangle from "@/lib/api/src/model/Triangle.js";
import ChaserCalculationApi from "@/lib/api/src/api/ChaserCalculationApi.js";
export default {
  name: "Dashboard",
  components: {
    RoomPlotCard,
    GeometryCard,
    MaterialCard,
    ScenarioCard,
    TimeSignalCard,
    QualityNumberCard,
  },
  data: () => ({
    geometry: null,
    materials: null,
    position: null,
    facesOfCube: null,
    weapon: null,

    roomPlotID: 1001,
    timePlotID: 1002,
    gaugePlotID: [1010, 1011],

    signalTime: [0],
    sourceSignalValue: [0],
    receiverSignalValue: [0],

    unit_decibel: " dB",
    unit_meter: " m",

    isAlertOpen: false,
    isBackendBusy: false,

    qualityNumbers: {
      pfander: 0,
      ahaah: 0,
    },
  }),
  created() {
    this.geometry = this.getGeometry;
    this.facesOfCube = this.getFacesOfCube;
    this.materials = this.getMaterials;
    this.position = this.getPosition;
    this.weapon = this.getWeapon;

    let isStanding = this.weapon.filter((item) => item.selected)[0]
      .shootingPosition;

    let shooterPositions = util.getShooterPositions(
      {
        x: this.geometry.length.x,
        y: this.geometry.length.y,
      },
      isStanding
    );
    for (let i = 0; i < this.position.shooter.length; i++) {
      this.position.shooter[i].x = shooterPositions[i].x;
      this.position.shooter[i].y = shooterPositions[i].y;
      this.position.shooter[i].z = shooterPositions[i].z;
    }

    let ratingPositions = util.getRatingPositions(
      {
        x: this.geometry.length.x,
        y: this.geometry.length.y,
      },
      isStanding
    );
    for (let i = 0; i < ratingPositions.length; i++) {
      this.position.rating[i].x = ratingPositions[i].x;
      this.position.rating[i].y = ratingPositions[i].y;
      this.position.rating[i].z = ratingPositions[i].z;
    }

    this.$bus.$on("contextEvent", ($contextPayload) => {
      if ($contextPayload == "callApi") {
        this.callApi();
      }
      if ($contextPayload == "showAlert") {
        this.isAlertOpen = true;
      }
    });
  },
  computed: {
    ...mapGetters([
      "getGeometry",
      "getMaterials",
      "getPosition",
      "getWeapon",
      "getFacesOfCube",
    ]),
  },
  methods: {
    emitContextEvent(contextPayload) {
      this.$bus.$emit("contextEvent", contextPayload);
    },
    callApi() {
      if (!this.isBackendBusy) {
        this.isBackendBusy = true;

        let damping_ground_reference = 1.0;
        let materials = Array(this.materials.length);
        for (let i = 0; i < materials.length; i++) {
          materials[i] = new Material(
            this.materials[i].attenuation,
            this.materials[i].class,
            this.materials[i].name
          );
        }
        let receiverPosition = new Point3d(
          this.position.rating.filter((item) => item.selected)[0].x,
          this.position.rating.filter((item) => item.selected)[0].y,
          this.position.rating.filter((item) => item.selected)[0].z
        );
        let sourcePosition = new Point3d(
          this.position.shooter.filter((item) => item.selected)[0].x,
          this.position.shooter.filter((item) => item.selected)[0].y,
          this.position.shooter.filter((item) => item.selected)[0].z
        );
        let vertices = geo.getTrianglesOfCube(this.geometry.length);
        let triangles = Array(vertices.length);
        for (let i = 0; i < triangles.length; i++) {
          let materialIndex = Math.floor(i / 2);
          triangles[i] = Triangle.constructFromObject({
            material_id: this.facesOfCube[materialIndex].material_id,
            name: this.facesOfCube[materialIndex].shortname,
            vertices: vertices[i],
          });
        }
        let weaponClass = this.weapon.filter((item) => item.selected)[0].class;

        let defaultClient = ApiClient.instance;
        let bearerAuth = defaultClient.authentications["bearerAuth"];
        bearerAuth.accessToken = getAccessToken();
        let apiInstance = new ChaserCalculationApi(defaultClient);
        let opts = {
          chaserInput: new ChaserInput(
            damping_ground_reference,
            materials,
            receiverPosition,
            sourcePosition,
            triangles,
            weaponClass
          ),
        };
        apiInstance.chaserPost(opts, (error, data, response) => {
          if (error) {
            console.error(error);
            this.isBackendBusy = false;
          } else {
            this.qualityNumbers.ahaah = response.body.quality_number_ahaah;
            this.qualityNumbers.pfander = response.body.quality_number_pfander;
            this.sourceSignalValue = response.body.source_signal;
            this.receiverSignalValue = response.body.receiver_signal;

            let samplingRate = 100000;
            let timeDelta = 1.0 / samplingRate;
            this.signalTime[0] = 0;
            for (let i = 1; i < this.sourceSignalValue.length; i++) {
              this.signalTime[i] = this.signalTime[i - 1] + timeDelta;
            }

            this.isBackendBusy = false;
          }
        });
      }
    },
  },
  params: {
    // Legt Titel und Einträge im Kontextmenu fest
    menu: [
      { title: "Dashboard Menu", subtitle: "Subtitle" },
      {
        entry: [
          {
            name: "Berechnung starten",
            icon: "mdi-play",
            contextHandle: "callApi",
            divider: false,
          },
          {
            name: "Ergebnisse speichern",
            icon: "mdi-content-save",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Report herunterladen",
            icon: "mdi-cloud-download",
            contextHandle: "showAlert",
            divider: false,
          },
        ],
      },
    ],
  },
};
</script>

<style lang="postcss">
.disable-events {
  pointer-events: none;
}
</style>
