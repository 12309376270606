<template>
  <div>
    <!-- Plot -->
    <div :ref="uuid" v-bind="(plotData, plotLayout)" />
    <!-- Floating Action Button -->
    <v-speed-dial
      v-model="isFabOpen"
      bottom
      right
      absolute
      transition="slide-y-reverse-transition"
    >
      <!-- Floating Action Button -->
      <template v-slot:activator>
        <!-- Tooltip Optionen / Schließen -->
        <v-tooltip left v-if="isFabOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Schließen</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <span>Optionen</span>
        </v-tooltip>
      </template>
      <!-- Tooltip Zurücksetzen -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="accent"
            @click="resetCamera"
            class="mb-n1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-restore</v-icon>
          </v-btn>
        </template>
        <span>Zurücksetzen</span>
      </v-tooltip>
      <!-- Tooltip Herunterladen -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="accent"
            @click="downloadImage"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Herunterladen</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import * as geo from "@/lib/geometry/index.js";
import * as util from "@/lib/util/index.js";

export default {
  props: {
    uuid: Number,
    geometry: Object,
    position: Object,
    showFace: Number,
    showShooterPositions: Boolean,
  },
  data: () => ({
    isFabOpen: false,

    plotDataTemplate: {
      x: Array(),
      y: Array(),
      z: Array(),
      i: Array(),
      j: Array(),
      k: Array(),
      hoverinfo: "none",
      facecolor: Array(12),
      type: "mesh3d",
      showlegend: false,
      opacity: Number,
    },

    markerDataTemplate: {
      x: Array(),
      y: Array(),
      z: Array(),
      hoverinfo: "none",
      mode: "lines",
      line: {
        color: String(),
        width: 5,
      },
      type: "scatter3d",
    },

    shooterDataTemplate: {
      x: Array(6),
      y: Array(6),
      z: Array(6),
      text: [
        "P<sub>S,ZI-R,w</sub>",
        "P<sub>S,ZI-R,m</sub>",
        "P<sub>S,ZII,w</sub>",
        "P<sub>S,ZII,m</sub>",
        "P<sub>S,ZI,w</sub>",
        "P<sub>S,ZI,m</sub>",
      ],
      textfont: {
        size: 16,
      },
      hoverinfo: "none",      
      textposition: "top",
      mode: "markers+text",
      marker: {
        color: "rgb(46,64,107)",
        size: 5,
      },
      type: "scatter3d",
    },

    plotLayout: {
      scene: {
        aspectmode: "data",
        xaxis: {
          title: "<b>x [m]</b>",
          showspikes: false,
        },
        yaxis: {
          title: "<b>y [m]</b>",
          showspikes: false,
        },
        zaxis: {
          title: "<b>z [m]</b>",
          showspikes: false,
        },
        camera: {
          eye: {
            x: Number,
            y: Number,
            z: Number,
          },
        },
      },
      autosize: true,
      hovermode: false,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      showlegend: false,
      font: {
        family: "Roboto, sans-serif",
      },
    },
  }),
  mounted() {
    this.plotLayout.scene.camera.eye.x = this.geometry.camera.x;
    this.plotLayout.scene.camera.eye.y = this.geometry.camera.y;
    this.plotLayout.scene.camera.eye.z = this.geometry.camera.z;
    Plotly.newPlot(this.$refs[this.uuid], this.plotData, this.plotLayout, {
      displayModeBar: false,
    });
  },
  computed: {
    plotData: function () {
      let plotDataObject = this.updatePlotDataObject();

      if (this.showFace != null) {
        let markerDataObject = this.updateMarkerDataObject();
        if (this.showShooterPositions) {
          let shooterDataObject = this.updateShooterDataObject();
          return [plotDataObject, markerDataObject, shooterDataObject];
        } else {
          return [plotDataObject, markerDataObject];
        }
      } else {
        if (this.showShooterPositions) {
          let shooterDataObject = this.updateShooterDataObject();
          return [plotDataObject, shooterDataObject];
        } else {
          return [plotDataObject];
        }
      }
    },
  },
  watch: {
    plotData: {
      handler() {
        Plotly.react(this.$refs[this.uuid], this.plotData, this.plotLayout);
      },
      deep: true,
    },
  },
  methods: {
    updateShooterDataObject() {
      let shooterDataObject = {};
      Object.assign(shooterDataObject, this.shooterDataTemplate);

      for (let i = 0; i < this.position.shooter.length; i++) {
        shooterDataObject.x[i] = this.position.shooter[i].x;
        shooterDataObject.y[i] = this.position.shooter[i].y;
        shooterDataObject.z[i] = this.position.shooter[i].z;
      }

      return shooterDataObject;
    },
    updatePlotDataObject() {
      let plotDataObject = this.plotDataTemplate;

      let vertex = geo.getVerticesOfCube(this.geometry.length);
      let trisurf = geo.getEdgeIndicesOfCube();

      // Vertices
      plotDataObject.x = vertex.x;
      plotDataObject.y = vertex.y;
      plotDataObject.z = vertex.z;

      // Indices der Kanten der Dreiecke
      plotDataObject.i = trisurf.i;
      plotDataObject.j = trisurf.j;
      plotDataObject.k = trisurf.k;

      // Farbe der Seitenwand links
      plotDataObject.facecolor[0] = this.geometry.color[0];
      plotDataObject.facecolor[1] = this.geometry.color[0];

      // Farbe des Boden
      plotDataObject.facecolor[2] = this.geometry.color[2];
      plotDataObject.facecolor[3] = this.geometry.color[2];

      // Farbe der Decke
      plotDataObject.facecolor[4] = this.geometry.color[3];
      plotDataObject.facecolor[5] = this.geometry.color[3];

      // Farbe der Seitenwand rechts
      plotDataObject.facecolor[6] = this.geometry.color[1];
      plotDataObject.facecolor[7] = this.geometry.color[1];

      // Farbe des Geschossfang
      plotDataObject.facecolor[8] = this.geometry.color[4];
      plotDataObject.facecolor[9] = this.geometry.color[4];

      // Farbe der Rückwand
      plotDataObject.facecolor[10] = this.geometry.color[5];
      plotDataObject.facecolor[11] = this.geometry.color[5];

      // Deckkraft der Farbe
      plotDataObject.opacity = this.geometry.opacity;

      return plotDataObject;
    },
    updateMarkerDataObject() {
      let markerDataObject = this.markerDataTemplate;

      let range = geo.getRangeOfCube(this.geometry.length);
      let edges = geo.getEdgeIndicesOfQuad(this.showFace);
      edges = geo.scaleVertex(edges, range);

      markerDataObject.x = edges.x;
      markerDataObject.y = edges.y;
      markerDataObject.z = edges.z;

      markerDataObject.line.color = this.geometry.faceColor;

      return markerDataObject;
    },
    resetCamera() {
      this.plotLayout.scene.camera.eye.x = this.geometry.camera.x;
      this.plotLayout.scene.camera.eye.y = this.geometry.camera.y;
      this.plotLayout.scene.camera.eye.z = this.geometry.camera.z;
      Plotly.relayout(this.$refs[this.uuid], this.plotLayout);
    },
    downloadImage() {
      let timeStamp = util.getTimeStamp();
      Plotly.downloadImage(this.$refs[this.uuid], {
        format: "png",
        height: 900,
        width: 1600,
        filename: "chaser-3d-view-" + timeStamp,
      });
    },
  },
};
</script>