<template>
  <div>
    <!-- Plot -->
    <div :ref="uuid" v-bind="(plotData, plotLayout)" />
    <!-- Floating Action Button -->
    <v-speed-dial
      v-model="isFabOpen"
      bottom
      right
      absolute
      transition="slide-y-reverse-transition"
    >
      <!-- Floating Action Button -->
      <template v-slot:activator>
        <!-- Tooltip Optionen / Schließen -->
        <v-tooltip left v-if="isFabOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Schließen</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <span>Optionen</span>
        </v-tooltip>
      </template>
      <!-- Tooltip Herunterladen -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="accent"
            @click="downloadImage"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Herunterladen</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    uuid: Number,
    geometry: Object,
    position: Object,
    showSelectedShooterPosition: Boolean,
    showSelectedRatingPosition: Boolean,
  },
  data: () => ({
    isFabOpen: false,
    weaponLength: 1,
    shooterMarkerOffset: 0.4,

    shooterDataTemplate: {
      x: Array(6),
      y: Array(6),
      text: Array(6),
      textposition: "bottom",
      textfont: {
        size: 16,
      },
      showlegend: false,
      hovertemplate: "(%{x:.2f} m, %{y:.2f} m)<extra></extra>",
      selectedpoints: Array.from(Array(6).keys()),
      mode: "markers+text",
      marker: {
        color: "rgb(46,64,107)",
        size: 12,
      },
      type: "scatter",
    },

    ratingDataTemplate: {
      x: Array(12),
      y: Array(12),
      text: Array(12),
      textposition: "left",
      textfont: {
        size: 16,
      },
      showlegend: false,
      hovertemplate: "(%{x:.2f} m, %{y:.2f} m)<extra></extra>",
      selectedpoints: Array.from(Array(12).keys()),
      mode: "markers+text",
      marker: {
        color: "rgb(212,46,18)",
        size: 8,
      },
      type: "scatter",
    },

    weaponDataTemplate: {
      x: Array(2),
      y: Array(2),
      line: {
        color: "rgba(46,64,107,1)",
        width: 5,
      },
      type: "scatter",
      mode: "lines",
      showlegend: false,
      hovermode: false,
      hoverinfo: "skip",
    },

    plotLayoutTemplate: {
      autosize: true,
      margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 50,
      },
      font: {
        family: "Roboto, sans-serif",
      },
      hovermode: "closest",
      xaxis: {
        title: "<b>Länge x [m]</b>",
        autorange: "reversed",
        automargin: true,
        zeroline: false,
        showgrid: true
      },
      yaxis: {
        title: "<b>Breite y [m]</b>",
        autorange: "reversed",
        automargin: true,
        zeroline: false,
      },
      shapes: [
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: 0,
          y0: Number,
          x1: Number,
          y1: Number,
          line: {
            color: "black",
            width: 3,
          },
          fillcolor: "rgba(213,226,245, 0.2)",
          layer: "below",
        },
      ],
    },
  }),
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.plotData, this.plotLayout, {
      displayModeBar: false,
    });
  },
  computed: {
    plotData: function () {
      let shooterDataObject = {};
      Object.assign(shooterDataObject, this.shooterDataTemplate);

      let ratingDataObject = this.ratingDataTemplate;
      Object.assign(ratingDataObject, this.ratingDataTemplate);

      for (let i = 0; i < this.position.shooter.length; i++) {
        shooterDataObject.x[i] = this.position.shooter[i].x;
        shooterDataObject.y[i] = this.position.shooter[i].y;
        shooterDataObject.text[i] =
          this.position.shooter[i].name[0] +
          "<sub>" +
          this.position.shooter[i].name.substring(1) +
          "</sub>";
      }

      for (let i = 0; i < this.position.rating.length; i++) {
        ratingDataObject.x[i] = this.position.rating[i].x;
        ratingDataObject.y[i] = this.position.rating[i].y;
        ratingDataObject.text[i] =
          this.position.rating[i].name[0] +
          "<sub>" +
          this.position.rating[i].name.substring(1) +
          "</sub>";
      }

      // Berechnung der Beurteilungsposition für Plot
      var selectedRatingIndices;
      if (this.showSelectedShooterPosition) {
        selectedRatingIndices = Array(2);

        let selectedShooterIndex =
          this.position.shooter.filter((item) => item.selected)[0].number - 1;
        shooterDataObject.selectedpoints = [selectedShooterIndex];

        if (this.showSelectedRatingPosition) {
          selectedRatingIndices = [
            selectedShooterIndex * 2 +
              this.position.rating.filter((item) => item.selected)[0].number -
              1,
          ];
        } else {
          selectedRatingIndices = [
            selectedShooterIndex * 2,
            selectedShooterIndex * 2 + 1,
          ];
        }
      } else {
        if (this.showSelectedRatingPosition) {
          selectedRatingIndices = Array(6);

          let positionOffset =
            this.position.rating.filter((item) => item.selected)[0].number - 1;
          selectedRatingIndices = [
            0 + positionOffset,
            2 + positionOffset,
            4 + positionOffset,
            6 + positionOffset,
            8 + positionOffset,
            10 + positionOffset,
          ];
        } else {
          selectedRatingIndices = Array.from(Array(12).keys());
        }

        shooterDataObject.selectedpoints = Array.from(Array(6).keys());
      }
      ratingDataObject.selectedpoints = selectedRatingIndices;

      let plotDataArray = Array();
      plotDataArray.push(shooterDataObject);
      plotDataArray.push(ratingDataObject);

      for (let i = 0; i < this.position.shooter.length; i++) {
        let weaponData = {};
        Object.assign(weaponData, this.weaponDataTemplate);

        weaponData.x = [
          this.position.shooter[i].x - this.shooterMarkerOffset,
          this.position.shooter[i].x - this.weaponLength,
        ];
        weaponData.y = [this.position.shooter[i].y, this.position.shooter[i].y];

        if (shooterDataObject.selectedpoints.length == 1) {
          if (i == shooterDataObject.selectedpoints[0]) {
            weaponData.line = {
              color: this.weaponDataTemplate.line.color,
              width: this.weaponDataTemplate.line.width,
            };
          } else {
            weaponData.line = {
              color: "rgba(46,64,107,0.2)",
              width: this.weaponDataTemplate.line.width,
            };
          }
        }

        plotDataArray.push(weaponData);
      }

      return plotDataArray;
    },
    plotLayout: function () {
      let plotLayoutObject = this.plotLayoutTemplate;

      plotLayoutObject.shapes[0].x1 = this.geometry.length.x;
      plotLayoutObject.shapes[0].y0 = -this.geometry.length.y / 2;
      plotLayoutObject.shapes[0].y1 = this.geometry.length.y / 2;

      return plotLayoutObject;
    },
  },
  watch: {
    plotData: {
      handler(value) {
        Plotly.react(this.$refs[this.uuid], value, this.plotLayout);
      },
      deep: true,
    },
  },
  methods: {
    resetCamera() {
      Plotly.relayout(this.$refs[this.uuid], {
        "xaxis.autorange": "reversed",
        "yaxis.autorange": "reversed",
      });
    },
    downloadImage() {
      let timeStamp = this.getTimeStamp();

      Plotly.downloadImage(this.$refs[this.uuid], {
        format: "png",
        height: 900,
        width: 1600,
        filename: "chaser-2d-view-" + timeStamp,
      });
    },
    getTimeStamp() {
      let date = new Date();

      let days = ("0" + date.getDate().toString()).slice(-2);
      let month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
      let year = date.getFullYear().toString();

      let hour = ("0" + date.getHours().toString()).slice(-2);
      let minute = ("0" + date.getMinutes().toString()).slice(-2);
      let second = ("0" + date.getSeconds().toString()).slice(-2);

      return year + month + days + "T" + hour + minute + second;
    },
  },
};
</script>