var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v(" Materialparameter "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"icon":"","to":"/materialien"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-wrench")])],1)]}}])},[_c('span',[_vm._v("Bearbeiten")])]),(_vm.showMaterial)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showMaterial = !_vm.showMaterial}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,false,1819335762)},[_c('span',[_vm._v("Minimieren")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showMaterial = !_vm.showMaterial}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('span',[_vm._v("Maximieren")])])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMaterial),expression:"showMaterial"}]},[_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.materialTableHeader,"items":_vm.facesOfCube,"hide-default-footer":true,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.materials[_vm.facesOfCube[item.number].material_id].class)+" ")]}},{key:"item.attenuation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.materials[_vm.facesOfCube[item.number].material_id].attenuation .toString() .concat(_vm.unit_decibel))+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"disable-events",attrs:{"small":"","color":_vm._rgbToHex(item.color)}})]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }