/**
 * @summary Funktionssammlung für das Chaser Web Tool
 * @author Carsten Hudasch <hudasch@cervus.de>
 */

/**
 * Offset der Schützenposition in x-Richtung
 */
const xOffsetShooter = 12.5;

/**
 * Offset der Schützenposition in y-Richtung
 */
const yOffsetShooter = 2;

/**
 * x-Koordinate der mittleren Schützenposition
 */
const xMiddleShooter = 25;

/**
 * Array mit den zwei möglichen z-Koordinaten der Schützenposition
 */
const zPositionShooter = [0.2, 1.6];

/**
 * Offset der Beurteilungsposition in x-Richtung
 */
const xOffsetRating = 2;

/**
 * Offset der Beurteilungsposition in y-Richtung
 */
const yOffsetRating = 1;

/**
 * Array mit den zwei möglichen z-Koordinaten der Beurteilungsposition
 */
const zPositionRating = [0.2, 1.6];

/**
 * Berechnet die sechs möglichen Schützenpositionen
 * @param {Object} geometry Definiert die x- und y-Längen des Schießraums
 * @param {Boolean} isStanding Wahr im Falle, dass der Schütze steht
 * @returns {Array} Array aus sechs Positions-Objekten { x, y, z }
 */
export function getShooterPositions({ x, y }, isStanding) {
  let shooterPositions = Array(6);

  shooterPositions[0] = {
    x: x - xOffsetShooter,
    y: (-1 * y) / 2 + yOffsetShooter,
    z: zPositionShooter[isStanding]
  };
  shooterPositions[1] = {
    x: x - xOffsetShooter,
    y: 0,
    z: zPositionShooter[isStanding]
  };
  shooterPositions[2] = {
    x: xMiddleShooter,
    y: (-1 * y) / 2 + yOffsetShooter,
    z: zPositionShooter[isStanding]
  };
  shooterPositions[3] = {
    x: xMiddleShooter,
    y: 0,
    z: zPositionShooter[isStanding]
  };
  shooterPositions[4] = {
    x: xOffsetShooter,
    y: (-1 * y) / 2 + yOffsetShooter,
    z: zPositionShooter[isStanding]
  };
  shooterPositions[5] = {
    x: xOffsetShooter,
    y: 0,
    z: zPositionShooter[isStanding]
  };

  return shooterPositions;
}

/**
 * Berechnet die sechs möglichen x-Koordinaten des Schützen.
 * @param {Number} x Definiert die x-Länge des Schießraums
 * @returns Array aus sechs möglichen x-Koordinaten
 */
export function getShooterXPositions(x) {
  let xPositions = Array(6);

  xPositions[0] = x - xOffsetShooter;
  xPositions[1] = x - xOffsetShooter;
  xPositions[2] = xMiddleShooter;
  xPositions[3] = xMiddleShooter;
  xPositions[4] = xOffsetShooter;
  xPositions[5] = xOffsetShooter;

  return xPositions;
}

/**
 * Berechnet die sechs möglichen y-Koordinaten des Schützen.
 * @param {Number} y Definiert die y-Länge des Schießraums
 * @returns Array aus sechs möglichen y-Koordinaten
 */
export function getShooterYPositions(y) {
  let yPositions = Array(6).fill(0);

  for (let i = 0; i < yPositions.length; i = i + 2) {
    yPositions[i] = (-1 * y) / 2 + yOffsetShooter;
  }

  return yPositions;
}

/**
 * Berechnet die sechs möglichen z-Koordinaten des Schützen.
 * @param {Boolean} isStanding Wahr im Falle, dass der Schütze steht
 * @returns  Array aus sechs möglichen z-Koordinaten
 */
export function getShooterZPositions(isStanding) {
  return Array(6).fill(zPositionShooter[isStanding]);
}

/**
 * Berechnet die 12 Beurteilungspositionen für alle Schützenpositionen
 * @param {Object} geometry Definiert die x- und y-Längen des Schießraums 
 * @param {Boolean} isStanding Wahr im Falle, dass der Schütze steht
 * @returns Array aus 12 möglichen Beurteilungsposition (x-, y- und z-Koordinaten)
 */
export function getRatingPositions({ x, y }, isStanding) {
  let ratingPositions = Array(12);
  let shooterPositions = getShooterPositions({ x, y });

  let xOffset = 2;
  let yOffset = 1;
  let zPosition = [0.2, 1.6];

  for (let i = 0; i < shooterPositions.length; i++) {
    // Berechnung von PB1
    ratingPositions[i * 2] = {
      x: shooterPositions[i].x,
      y: shooterPositions[i].y - yOffset,
      z: zPosition[isStanding],
    };

    // Berechnung von PB2
    ratingPositions[i * 2 + 1] = {
      x: shooterPositions[i].x + xOffset,
      y: shooterPositions[i].y,
      z: zPosition[1],
    };
  }

  return ratingPositions;
}

/**
 * Berechnet die 12 möglichen x-Koordinaten der Beurteilungspositionen.
 * @param {Number} x Definiert die x-Länge des Schießraums
 * @returns Array aus 12 möglichen x-Koordinaten der Beurteilungspositionen
 */
export function getRatingXPositions(x) {
  let ratingXPositions = Array(12);
  let shooterXPositions = getShooterXPositions(x);

  for (let i = 0; i < shooterXPositions.length; i++) {
    // PB1
    ratingXPositions[i * 2] = shooterXPositions[i];
    // PB2
    ratingXPositions[i * 2 + 1] = shooterXPositions[i] + xOffsetRating;
  }

  return ratingXPositions;
}

/**
 * Berechnet die 12 möglichen y-Koordinaten der Beurteilungspositionen.
 * @param {Number} y Definiert die y-Länge des Schießraums
 * @returns Array aus 12 möglichen y-Koordinaten der Beurteilungspositionen
 */
export function getRatingYPositions(y) {
  let ratingYPositions = Array(12);
  let shooterYPositions = getShooterYPositions(y);

  for (let i = 0; i < shooterYPositions.length; i++) {
    // PB1
    ratingYPositions[i * 2] = shooterYPositions[i] - yOffsetRating;
    // PB2
    ratingYPositions[i * 2 + 1] = shooterYPositions[i];
  }

  return ratingYPositions;
}

/**
 * Berechnet die 12 möglichen z-Koordinaten der Beurteilungspositionen.
 * @param {Boolean} isStanding Wahr im Falle, dass der Schütze steht
 * @returns Array aus 12 möglichen z-Koordinaten der Beurteilungspositionen
 */
export function getRatingZPositions(isStanding) {
  let ratingZPositions = Array(12).fill(zPositionRating[1]);

  if (!isStanding) {
    for (let i = 0; i < ratingZPositions.length / 2; i++) {
      ratingZPositions[i * 2] = zPositionRating[0];
    }
  }

  return ratingZPositions;
}

/**
 * Berechnet ein Timestamp in Anlehnung an ISO 8601
 * @returns {String} Zeichenkette mit Zeit und Datum
 */
export function getTimeStamp() {
  let date = new Date();

  let days = ("0" + date.getDate().toString()).slice(-2);
  let month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
  let year = date.getFullYear().toString();

  let hour = ("0" + date.getHours().toString()).slice(-2);
  let minute = ("0" + date.getMinutes().toString()).slice(-2);
  let second = ("0" + date.getSeconds().toString()).slice(-2);

  return year + month + days + "T" + hour + minute + second;
}

/**
 * Wandelt ein RGB-Farbobjekt in eine Hexadezimal-Farbzeichenkette um
 * @param {Object} color Objekt mit den RGB-Farbwerten
 * @returns {String} Zeichenkette mit den HEX-Farbwerten
 */
export function rgbToHex(color) {
  let r = ("0" + color.r.toString(16)).slice(-2);
  let g = ("0" + color.g.toString(16)).slice(-2);
  let b = ("0" + color.b.toString(16)).slice(-2);

  return "#" + r + g + b;
}
