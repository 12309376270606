var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("Schützenposition")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","item-key":"number","headers":_vm.shooterTableHeaders,"hide-default-footer":true,"items":_vm.position.shooter,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-check-bold")])]},proxy:true},{key:"header.number",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text[0])),_c('sub',[_vm._v(_vm._s(header.text[1]))]),_vm._v(_vm._s(header.text.substring(2))+" ")]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.setSelectedShooter(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name[0])),_c('sub',[_vm._v(_vm._s(item.name.substring(1)))])])]}},{key:"item.x",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.x.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}},{key:"item.y",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.y.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}},{key:"item.z",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.z.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}}])}),_c('v-switch',{attrs:{"label":"Nur ausgewählte Schützenposition anzeigen"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("mdi-arrow-expand-right")])]},proxy:true}]),model:{value:(_vm.showSelectedShooterPosition),callback:function ($$v) {_vm.showSelectedShooterPosition=$$v},expression:"showSelectedShooterPosition"}})],1)],1),_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("Beurteilungspositionen")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.ratingTableHeaders,"items":_vm.position.rating.filter(
                function (item) { return item.parent ==
                  _vm.position.shooter.filter(function (item) { return item.selected; })[0].number; }
              ),"hide-default-footer":true,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-check-bold")])]},proxy:true},{key:"header.parent",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(header.text[0])),_c('sub',[_vm._v(_vm._s(header.text[1]))]),_vm._v(_vm._s(header.text.substring(2))+" ")]}},{key:"item.data-table-select",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.setSelectedRating(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name[0])),_c('sub',[_vm._v(_vm._s(item.name.substring(1)))])])]}},{key:"item.x",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.x.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}},{key:"item.y",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.y.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}},{key:"item.z",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.z.toFixed(2)) .toString() .concat(_vm.unit_meter))+" ")]}}])}),_c('v-switch',{attrs:{"label":"Nur ausgewählte Beurteilungsposition anzeigen"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])]},proxy:true}]),model:{value:(_vm.showSelectedRatingPosition),callback:function ($$v) {_vm.showSelectedRatingPosition=$$v},expression:"showSelectedRatingPosition"}})],1)],1),_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("Waffenklasse")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","item-key":"number","headers":_vm.weaponTableHeaders,"hide-default-footer":true,"items":_vm.weapon,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-check-bold")])]},proxy:true},{key:"item.data-table-select",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.setSelectedWeapon(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.radius",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.radius .toFixed(2) .toString() .concat(_vm.unit_meter))+" ")]}},{key:"item.peaklevel",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.peaklevel .toFixed(1) .toString() .concat(_vm.unit_decibel))+" ")]}},{key:"item.shootingPosition",fn:function(ref){
              var item = ref.item;
return [_c('v-chip-group',{attrs:{"row":"","single":"","mandatory":"","active-class":"primary"},on:{"change":_vm.setZPositions},model:{value:(item.shootingPosition),callback:function ($$v) {_vm.$set(item, "shootingPosition", $$v)},expression:"item.shootingPosition"}},[_c('v-chip',{attrs:{"small":"","label":"","disabled":!item.selected}},[_vm._v("Liegend")]),(item.class != 'Maschinengewehr')?_c('v-chip',{attrs:{"small":"","label":"","disabled":!item.selected}},[_vm._v("Stehend")]):_vm._e()],1)]}}])})],1)],1)],1),_c('v-col',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v("2D-Ansicht")]),_c('v-divider'),_c('v-card-text',[_c('FloorPlot',{attrs:{"uuid":_vm.floorPlotID,"geometry":_vm.geometry,"position":_vm.position,"showSelectedShooterPosition":_vm.showSelectedShooterPosition,"showSelectedRatingPosition":_vm.showSelectedRatingPosition}})],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-8 mr-8",attrs:{"fab":"","dark":"","color":"primary","to":"/dashboard","bottom":"","right":"","fixed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-view-dashboard")])],1)]}}])},[_c('span',[_vm._v("Dashboard")])])],1),_c('v-snackbar',{attrs:{"timeout":"5000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.isAlertOpen = false}}},'v-btn',attrs,false),[_vm._v(" Schließen ")])]}}]),model:{value:(_vm.isAlertOpen),callback:function ($$v) {_vm.isAlertOpen=$$v},expression:"isAlertOpen"}},[_vm._v("Funktion noch nicht implementiert. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }