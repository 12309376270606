<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <v-card class="mb-6">
          <v-card-title>Impressum</v-card-title>
          <v-divider />
          <v-card-text>
            <div class="mt-8 mb-4 subtitle-1">Herausgeber</div>
            <p class="text-justify">
              Die Webseite und Anwendung unter https://chaser.cervus.cc werden
              Ihnen angeboten von der Cervus Consult GmbH:
            </p>
            <v-row>
              <v-col cols="1"><v-icon>mdi-map-marker</v-icon></v-col>
              <v-col class="mx-n6">
                <span
                  >Cervus Consult GmbH<br />
                  Gießerallee 33<br />
                  47877 Willich<br />
                  Deutschland
                </span></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="1"><v-icon>mdi-phone</v-icon></v-col>
              <v-col class="mx-n6">+49 2154 89719-0</v-col>
            </v-row>
            <v-row>
              <v-col cols="1"><v-icon>mdi-fax</v-icon></v-col>
              <v-col class="mx-n6">+49 2154 89719-99</v-col>
            </v-row>
            <v-row>
              <v-col cols="1"><v-icon>mdi-email</v-icon></v-col>
              <v-col class="mx-n6"
                ><a href="mailto:consult@cervus.de">consult@cervus.de</a></v-col
              >
            </v-row>
            <div class="mt-8 mb-4 subtitle-1">Geschäftsführer</div>
            <p>
              Frank Hammelmann <br />
              Dr. Christian Kleinhenrich
            </p>
            <div class="mt-8 mb-4 subtitle-1">Umsatzsteuer</div>
            <p>
              Finanzamt Viersen <br />
              USt-IdNr.: DE 257750459
            </p>
            <div class="mt-8 mb-4 subtitle-1">Handelsregister</div>
            <p>
              Amtsgericht Krefeld <br />
              HRB 12364
            </p>
            <div class="mt-8 mb-4 subtitle-1">Haftung für Inhalte</div>
            <p class="text-justify">
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei bekannt werden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <div class="mt-8 mb-4 subtitle-1">Haftung für Links</div>
            <p class="text-justify">
              Das Angebot enthält Links zu externen Webseiten Dritter, auf deren
              Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
              fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
              verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
              der Seiten verantwortlich. Die verlinkten Seiten wurden zum
              Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
              Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
              erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
              Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei bekannt werden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <div class="mt-8 mb-4 subtitle-1">Urheberrecht</div>
            <p class="text-justify">
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge
              Dritter sind als solche gekennzeichnet. Die Vervielfältigung,
              Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
              des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser
              Seite sind nur für den privaten, nicht kommerziellen Gebrauch
              gestattet.
            </p>
            <span class="caption">Quelle der Vorlage: eRecht24.de</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Floating Action Button -->
    <v-tooltip left v-if="user.isAuth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          color="primary"
          to="/dashboard"
          bottom
          right
          fixed
          class="mb-8 mr-8"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-large>mdi-view-dashboard</v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Impressum",
  data: () => ({
    user: {},
  }),
  created() {
    this.user = this.getUser;
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  params: {
    menu: [
      { title: "Impressum Menu", subtitle: "Subtitle" },
      {
        entry: [],
      },
    ],
  },
};
</script>