<template>
  <div>
    <div :ref="uuid" v-bind="(plotData, plotLayout)" />
    <!-- Floating Action Button -->
    <v-speed-dial
      v-model="isFabOpen"
      bottom
      right
      absolute
      transition="slide-y-reverse-transition"
    >
      <!-- Floating Action Button -->
      <template v-slot:activator>
        <!-- Tooltip Optionen / Schließen -->
        <v-tooltip left v-if="isFabOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Schließen</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-model="isFabOpen"
              color="primary"
              dark
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <span>Optionen</span>
        </v-tooltip>
      </template>
      <!-- Tooltip Zurücksetzen -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="accent"
            @click="resetCamera"
            class="mb-n1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-restore</v-icon>
          </v-btn>
        </template>
        <span>Zurücksetzen</span>
      </v-tooltip>
      <!-- Tooltip Herunterladen -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="accent"
            @click="downloadImage"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <span>Herunterladen</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: {
    uuid: Number,
    time: Array,
    value: Array,
  },
  data: () => ({
    isFabOpen: false,
    plotLayout: {
      autosize: true,
      margin: {
        l: 65,
        r: 65,
        b: 65,
        t: 65,
      },
      font: {
        family: "Roboto, sans-serif",
      },
      legend: {
        xanchor: "right",
        font: {
          size: 14,
        },
      },
      hovermode: "closest",
      xaxis: {
        title: "<b>Zeit t [s]</b>",
        autorange: true,
        automargin: true,
        hoverformat: ".2f",
      },
      yaxis: {
        title: "<b>Schalldruck P [Pa]</b>",
        automargin: true,
      },
    },
  }),
  watch: {
    plotData: {
      handler() {
        Plotly.react(this.$refs[this.uuid], this.plotData, this.plotLayout);
      },
      deep: true,
    },
  },
  computed: {
    plotData: function () {
      var plotDataArray;
      if (this.value.length == 2) {
        plotDataArray = Array(2);
        plotDataArray[0] = {
          x: this.time[0],
          y: this.value[0],
          line: {
            color: "#627090",
            width: 2,
          },
          type: "scatter",
          mode: "lines",
          showlegend: true,
          name: "Quelle",
          hovertemplate: "(%{x:.3f} s, %{y:.3f} Pa)<extra></extra>",
        };
        plotDataArray[1] = {
          x: this.time[1],
          y: this.value[1],
          line: {
            color: "#D42E12",
            width: 2,
          },
          type: "scatter",
          mode: "lines",
          showlegend: true,
          name: "Empfänger",
          hovertemplate: "(%{x:.3f} s, %{y:.3f} Pa)<extra></extra>",
        };
      } else {
        plotDataArray = [
          {
            x: this.time,
            y: this.value,
            line: {
              color: "#627090",
              width: 2,
            },
            type: "scatter",
            mode: "lines",
            showlegend: false,
            hovertemplate: "(%{x:.3f} s, %{y:.3f} Pa)<extra></extra>",
          },
        ];
      }

      return plotDataArray;
    },
  },
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.plotData, this.plotLayout, {
      displayModeBar: false,
    });
  },
  methods: {
    resetCamera() {
      Plotly.relayout(this.$refs[this.uuid], {
        "xaxis.autorange": true,
        "yaxis.autorange": true,
      });
    },
    downloadImage() {
      let timeStamp = this.getTimeStamp();

      Plotly.downloadImage(this.$refs[this.uuid], {
        format: "png",
        height: 900,
        width: 1600,
        filename: "chaser-timesignal-" + timeStamp,
      });
    },
    getTimeStamp() {
      let date = new Date();

      let days = ("0" + date.getDate().toString()).slice(-2);
      let month = ("0" + (date.getMonth() + 1).toString()).slice(-2);
      let year = date.getFullYear().toString();

      let hour = ("0" + date.getHours().toString()).slice(-2);
      let minute = ("0" + date.getMinutes().toString()).slice(-2);
      let second = ("0" + date.getSeconds().toString()).slice(-2);

      return year + month + days + "T" + hour + minute + second;
    },
  },
};
</script>

<style lang="postcss">
div.plotly-notifier {
  visibility: hidden;
}
</style>
