/**
 * Cervus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Audio model module.
 * @module model/Audio
 * @version 1.0.0
 */
class Audio {
    /**
     * Constructs a new <code>Audio</code>.
     * Audio signal
     * @alias module:model/Audio
     */
    constructor() { 
        
        Audio.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Audio</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Audio} obj Optional instance to populate.
     * @return {module:model/Audio} The populated <code>Audio</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Audio();

            if (data.hasOwnProperty('time')) {
                obj['time'] = ApiClient.convertToType(data['time'], ['Number']);
            }
            if (data.hasOwnProperty('time_signal')) {
                obj['time_signal'] = ApiClient.convertToType(data['time_signal'], ['Number']);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<Number>} time
 */
Audio.prototype['time'] = undefined;

/**
 * @member {Array.<Number>} time_signal
 */
Audio.prototype['time_signal'] = undefined;






export default Audio;

