<template>
  <v-card class="mb-6">
    <v-card-title>
      3D-Ansicht
      <v-spacer></v-spacer>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showRoomPlot">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showRoomPlot = !showRoomPlot"
            v-bind="attrs"
            v-on="on"
            v-if="isCardFoldable"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showRoomPlot = !showRoomPlot"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <!-- RoomPlot für 3D-Ansicht -->
    <v-expand-transition>
      <div v-show="showRoomPlot">
        <v-divider></v-divider>
        <RoomPlot :uuid="this.uuid" :geometry="this.geometry" />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import RoomPlot from "../plots/RoomPlot.vue";

export default {
  props: {
    uuid: Number,
    geometry: Object,
    isCardFoldable: Boolean,
  },
  components: {
    RoomPlot,
  },
  data: () => ({
    showRoomPlot: true,
  }),
};
</script>