<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- Karte für Schützenpositionen -->
        <v-card class="mb-6">
          <v-card-title>Schützenposition</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              show-select
              item-key="number"
              :headers="shooterTableHeaders"
              :hide-default-footer="true"
              :items="position.shooter"
              :items-per-page="-1"
              dense
            >
              <!-- Template für Icons im Header -->
              <template v-slot:header.data-table-select>
                <v-icon small class="ml-1">mdi-check-bold</v-icon>
              </template>
              <!-- Template für Tiefstellung im Tabellenkopf -->
              <template v-slot:header.number="{ header }">
                {{ header.text[0] }}<sub>{{ header.text[1] }}</sub
                >{{ header.text.substring(2) }}
              </template>
              <!-- Template für shooter.selected-Binding -->
              <template v-slot:item.data-table-select="{ item }">
                <v-simple-checkbox
                  @click="setSelectedShooter(item)"
                  v-model="item.selected"
                  :ripple="false"
                />
              </template>
              <!-- Template für Tiefstellung im Namen -->
              <template v-slot:item.name="{ item }">
                <span>
                  {{ item.name[0] }}<sub>{{ item.name.substring(1) }}</sub>
                </span>
              </template>
              <!-- Templates zum Anhängen der Einheit -->
              <template v-slot:item.x="{ item }">
                {{
                  parseFloat(item.x.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
              <template v-slot:item.y="{ item }">
                {{
                  parseFloat(item.y.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
              <template v-slot:item.z="{ item }">
                {{
                  parseFloat(item.z.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
            </v-data-table>
            <!-- Controls für Schützenpositionen -->
            <v-switch
              label="Nur ausgewählte Schützenposition anzeigen"
              v-model="showSelectedShooterPosition"
            >
              <template v-slot:prepend>
                <v-icon>mdi-arrow-expand-right</v-icon>
              </template>
            </v-switch>
          </v-card-text>
        </v-card>

        <!-- Karte für Beurteilungspositionen -->
        <v-card class="mb-6">
          <v-card-title>Beurteilungspositionen</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              show-select
              :headers="ratingTableHeaders"
              :items="
                position.rating.filter(
                  (item) =>
                    item.parent ==
                    position.shooter.filter((item) => item.selected)[0].number
                )
              "
              :hide-default-footer="true"
              :items-per-page="-1"
              dense
            >
              <!-- Template für Icons im Header -->
              <template v-slot:header.data-table-select>
                <v-icon small class="ml-1">mdi-check-bold</v-icon>
              </template>
              <!-- Template für Tiefstellung im Tabellenkopf -->
              <template v-slot:header.parent="{ header }">
                {{ header.text[0] }}<sub>{{ header.text[1] }}</sub
                >{{ header.text.substring(2) }}
              </template>
              <!-- Template für shooter.selected-Binding -->
              <template v-slot:item.data-table-select="{ item }">
                <v-simple-checkbox
                  @click="setSelectedRating(item)"
                  v-model="item.selected"
                  :ripple="false"
                />
              </template>
              <!-- Template für Tiefstellung im Namen -->
              <template v-slot:item.name="{ item }">
                <span>
                  {{ item.name[0] }}<sub>{{ item.name.substring(1) }}</sub>
                </span>
              </template>
              <!-- Templates zum Anhängen der Einheit -->
              <template v-slot:item.x="{ item }">
                {{
                  parseFloat(item.x.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
              <template v-slot:item.y="{ item }">
                {{
                  parseFloat(item.y.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
              <template v-slot:item.z="{ item }">
                {{
                  parseFloat(item.z.toFixed(2))
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
            </v-data-table>
            <!-- Controls für Schützenpositionen -->
            <v-switch
              label="Nur ausgewählte Beurteilungsposition anzeigen"
              v-model="showSelectedRatingPosition"
            >
              <template v-slot:prepend>
                <v-icon>mdi-arrow-collapse-right</v-icon>
              </template>
            </v-switch>
          </v-card-text>
        </v-card>

        <!-- Karte für Waffenklassen -->
        <v-card class="mb-6">
          <v-card-title>Waffenklasse</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              show-select
              item-key="number"
              :headers="weaponTableHeaders"
              :hide-default-footer="true"
              :items="weapon"
              :items-per-page="-1"
              dense
            >
              <!-- Template für Icons im Header -->
              <template v-slot:header.data-table-select>
                <v-icon small class="ml-1">mdi-check-bold</v-icon>
              </template>
              <!-- Template für weapon.selected-Binding -->
              <template v-slot:item.data-table-select="{ item }">
                <v-simple-checkbox
                  @click="setSelectedWeapon(item)"
                  v-model="item.selected"
                  :ripple="false"
                />
              </template>
              <!-- Template zum Anhängen der Einheit -->
              <template v-slot:item.radius="{ item }">
                {{
                  item.radius
                    .toFixed(2)
                    .toString()
                    .concat(unit_meter)
                }}
              </template>
              <template v-slot:item.peaklevel="{ item }">
                {{
                  item.peaklevel
                    .toFixed(1)
                    .toString()
                    .concat(unit_decibel)
                }}
              </template>
              <!-- Template für Anschlagsvariante -->
              <template v-slot:item.shootingPosition="{ item }">
                <v-chip-group
                  row
                  single
                  mandatory
                  v-model="item.shootingPosition"
                  active-class="primary"
                  @change="setZPositions"
                >
                  <v-chip small label :disabled="!item.selected"
                    >Liegend</v-chip
                  >
                  <v-chip
                    small
                    label
                    :disabled="!item.selected"
                    v-if="item.class != 'Maschinengewehr'"
                    >Stehend</v-chip
                  >
                </v-chip-group>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mb-6">
          <v-card-title>2D-Ansicht</v-card-title>
          <v-divider></v-divider>
          <v-card-text
            ><FloorPlot
              :uuid="floorPlotID"
              :geometry="geometry"
              :position="position"
              :showSelectedShooterPosition="showSelectedShooterPosition"
              :showSelectedRatingPosition="showSelectedRatingPosition"
          /></v-card-text>
        </v-card>
      </v-col>

      <!-- Floating Action Button -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            to="/dashboard"
            bottom
            right
            fixed
            class="mb-8 mr-8"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon x-large>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>Dashboard</span>
      </v-tooltip>
    </v-row>

    <v-snackbar v-model="isAlertOpen" timeout="5000"
      >Funktion noch nicht implementiert.
      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="isAlertOpen = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import FloorPlot from "../components/plots/FloorPlot.vue";
import * as util from "@/lib/util/index.js";

export default {
  name: "Szenario",
  components: {
    FloorPlot,
  },
  data: () => ({
    isAlertOpen: false,
    showSelectedShooterPosition: false,
    showSelectedRatingPosition: false,
    floorPlotID: 4001,

    geometry: null,
    position: null,
    weapon: null,
    unit_meter: " m",
    unit_decibel: " dB",

    shooterTableHeaders: [
      {
        text: "Bezeichnung",
        value: "name",
        align: "left",
        sortable: false,
        width: "28%",
      },
      {
        text: "PS-Nummer",
        value: "number",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "x-Koordinate",
        value: "x",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "y-Koordinate",
        value: "y",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "z-Koordinate",
        value: "z",
        align: "left",
        sortable: false,
        width: "15%",
      },
    ],
    ratingTableHeaders: [
      {
        text: "Bezeichnung",
        value: "name",
        align: "left",
        sortable: false,
        width: "28%",
      },
      {
        text: "PS-Nummer",
        value: "parent",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "x-Koordinate",
        value: "x",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "y-Koordinate",
        value: "y",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "z-Koordinate",
        value: "z",
        align: "left",
        sortable: false,
        width: "15%",
      },
    ],
    weaponTableHeaders: [
      {
        text: "Bezeichnung",
        value: "class",
        align: "left",
        sortable: false,
        width: "28%",
      },
      {
        text: "Spitzenpegel",
        value: "peaklevel",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "Weberradius",
        value: "radius",
        align: "left",
        sortable: false,
        width: "15%",
      },
      {
        text: "Anschlagsvariante",
        value: "shootingPosition",
        align: "left",
        sortable: false,
        width: "30%",
      },
    ],
  }),
  created() {
    this.geometry = this.getGeometry;
    this.position = this.getPosition;
    this.weapon = this.getWeapon;

    let isStanding = this.weapon.filter((item) => item.selected)[0]
      .shootingPosition;

    let shooterPositions = util.getShooterPositions(
      {
        x: this.geometry.length.x,
        y: this.geometry.length.y,
      },
      isStanding
    );
    for (let i = 0; i < this.position.shooter.length; i++) {
      this.position.shooter[i].x = shooterPositions[i].x;
      this.position.shooter[i].y = shooterPositions[i].y;
      this.position.shooter[i].z = shooterPositions[i].z;
    }

    let ratingPositions = util.getRatingPositions(
      {
        x: this.geometry.length.x,
        y: this.geometry.length.y,
      },
      isStanding
    );
    for (let i = 0; i < ratingPositions.length; i++) {
      this.position.rating[i].x = ratingPositions[i].x;
      this.position.rating[i].y = ratingPositions[i].y;
      this.position.rating[i].z = ratingPositions[i].z;
    }

    this.$bus.$on("contextEvent", ($contextPayload) => {
      if ($contextPayload == "showAlert") {
        this.isAlertOpen = true;
      }
    });
  },
  methods: {
    ...mapMutations(["setPosition"]),

    setSelectedShooter(payload) {
      this.position.shooter.forEach((item) => {
        item.selected = false;
      });
      this.position.shooter[payload.number - 1].selected = true;

      this.position.rating.forEach((item) => {
        item.selected = false;
      });
      this.position.rating[payload.number * 2 - 2].selected = true;
    },

    setSelectedRating(payload) {
      this.position.rating.forEach((item) => {
        item.selected = false;
      });
      this.position.rating[
        payload.parent * 2 + payload.number - 3
      ].selected = true;
    },

    setSelectedWeapon(payload) {
      this.weapon.forEach((item) => {
        item.selected = false;
      });
      this.weapon[payload.number - 1].selected = true;

      this.setZPositions(payload.shootingPosition);
      // setZPositions
    },

    setZPositions(payload) {
      let shooterZPositions = util.getShooterZPositions(payload);
      for (let i = 0; i < this.position.shooter.length; i++) {
        this.position.shooter[i].z = shooterZPositions[i];
      }

      let ratingZPositions = util.getRatingZPositions(payload);
      for (let i = 0; i < this.position.rating.length; i++) {
        this.position.rating[i].z = ratingZPositions[i];
      }
    },

    emitContextEvent(contextPayload) {
      this.$bus.$emit("contextEvent", contextPayload);
    },
  },
  computed: {
    ...mapGetters(["getGeometry", "getPosition", "getWeapon"]),
  },
  params: {
    // Legt Titel und Einträge im Kontextmenu fest
    menu: [
      { title: "Szenario Menu", subtitle: "Subtitle" },
      {
        entry: [
          {
            name: "Szenario zurücksetzen",
            icon: "mdi-restore",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Szenario speichern",
            icon: "mdi-content-save",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Szenario laden",
            icon: "mdi-cloud-upload",
            contextHandle: "showAlert",
            divider: false,
          },
        ],
      },
    ],
  },
};
</script>
