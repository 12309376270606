<template>
  <v-card class="mb-6">
    <v-card-title>
      Szenario
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/szenario" class="mr-5" v-bind="attrs" v-on="on">
            <v-icon>mdi-wrench</v-icon>
          </v-btn>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showScenario">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showScenario = !showScenario"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showScenario = !showScenario"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <!-- Ausgabe des Szenarios -->
    <v-expand-transition>
      <div v-show="showScenario">
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                :headers="shooterTableHeaders"
                :items="position.shooter.filter((item) => item.selected)"
                :hide-default-footer="true"
                :items-per-page="-1"
                dense
              >
                <!-- Template für Tiefstellung im Namen -->
                <template v-slot:item.name="{ item }">
                  <span>
                    {{ item.name[0] }}<sub>{{ item.name.substring(1) }}</sub>
                  </span>
                </template>
                <!-- Templates zum Anhängen der Einheit -->
                <template v-slot:item.x="{ item }">
                  {{ parseFloat(item.x.toFixed(2)).toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.y="{ item }">
                  {{ parseFloat(item.y.toFixed(2)).toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.z="{ item }">
                  {{ parseFloat(item.z.toFixed(2)).toString().concat(unit_meter) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="weaponTableHeaders"
                :items="this.weapon.filter((item) => item.selected)"
                :hide-default-footer="true"
                :items-per-page="-1"
                dense
              >
                <!-- Templates zum Anhängen der Einheit -->
                <template v-slot:item.radius="{ item }">
                  {{ item.radius.toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.peaklevel="{ item }">
                  {{ item.peaklevel.toString().concat(unit_decibel) }}
                </template>
                <template v-slot:item.shootingPosition="{ item }">
                  <span v-if="item.shootingPosition == 0">Liegend</span>
                  <span v-else>Stehend</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="ratingTableHeaders"
                :items="this.position.rating.filter((item) => item.selected)"
                :hide-default-footer="true"
                :items-per-page="-1"
                dense
              >
                <!-- Template für Tiefstellung im Namen -->
                <template v-slot:item.name="{ item }">
                  <span>
                    {{ item.name[0] }}<sub>{{ item.name.substring(1) }}</sub>
                  </span>
                </template>
                <!-- Templates zum Anhängen der Einheit -->
                <template v-slot:item.x="{ item }">
                  {{ parseFloat(item.x.toFixed(2)).toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.y="{ item }">
                  {{ parseFloat(item.y.toFixed(2)).toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.z="{ item }">
                  {{ parseFloat(item.z.toFixed(2)).toString().concat(unit_meter) }}
                </template>                
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    position: Object,
    weapon: Array,
  },
  data: () => ({
    showScenario: true,

    unit_decibel: " dB",
    unit_meter: " m",

    shooterTableHeaders: [
      {
        text: "Schützenposition",
        value: "name",
        sortable: false,
        width: "40%",
      },
      {
        text: "x-Koordinate",
        value: "x",
        sortable: false,
        width: "20%",
      },
      {
        text: "y-Koordinate",
        value: "y",
        sortable: false,
        width: "20%",
      },
      {
        text: "z-Koordinate",
        value: "z",
        sortable: false,
        width: "20%",
      },
    ],

    weaponTableHeaders: [
      {
        text: "Waffenklasse",
        value: "class",
        sortable: false,
        width: "40%",
      },
      {
        text: "Weberradius",
        value: "radius",
        sortable: false,
        width: "20%",
      },
      {
        text: "Spitzenpegel",
        value: "peaklevel",
        sortable: false,
        width: "20%",
      },
      {
        text: "Anschlagsvariante",
        value: "shootingPosition",
        sortable: false,
        width: "20%",
      },
    ],

    ratingTableHeaders: [
      {
        text: "Beurteilungsposition",
        value: "name",
        align: "left",
        sortable: false,
        width: "40%",
      },
      {
        text: "x-Koordinate",
        value: "x",
        align: "left",
        sortable: false,
        width: "20%",
      },
      {
        text: "y-Koordinate",
        value: "y",
        align: "left",
        sortable: false,
        width: "20%",
      },
      {
        text: "z-Koordinate",
        value: "z",
        align: "left",
        sortable: false,
        width: "20%",
      },
    ],
  }),
};
</script>