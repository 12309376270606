<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <UserCard :user="user" />
      </v-col>
    </v-row>
    <!-- Floating Action Button -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            to="/dashboard"
            bottom
            right
            fixed
            class="mb-8 mr-8"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon x-large>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>Dashboard</span>
      </v-tooltip>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserCard from "../components/cards/UserCard.vue";

export default {
  name: "Einstellungen",
  components: {
    UserCard,
  },
  data: () => ({
    user: {},
  }),
  created() {
    this.user = this.getUser;
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  params: {
    menu: [
      { title: "Settings Menu", subtitle: "Subtitle" },
      {
        entry: [],
      },
    ],
  },
};
</script>