/**
 * Cervus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Point3d from './Point3d';

/**
 * The Triangle model module.
 * @module model/Triangle
 * @version 1.0.0
 */
class Triangle {
    /**
     * Constructs a new <code>Triangle</code>.
     * @alias module:model/Triangle
     */
    constructor() { 
        
        Triangle.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Triangle</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Triangle} obj Optional instance to populate.
     * @return {module:model/Triangle} The populated <code>Triangle</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Triangle();

            if (data.hasOwnProperty('material_id')) {
                obj['material_id'] = ApiClient.convertToType(data['material_id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('vertices')) {
                obj['vertices'] = ApiClient.convertToType(data['vertices'], [Point3d]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} material_id
 */
Triangle.prototype['material_id'] = undefined;

/**
 * @member {String} name
 */
Triangle.prototype['name'] = undefined;

/**
 * @member {Array.<module:model/Point3d>} vertices
 */
Triangle.prototype['vertices'] = undefined;






export default Triangle;

