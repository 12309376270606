<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Materialparameter </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="facesTableHeader"
              :items="facesOfCube"
              show-select
              :single-select="true"
              v-model="selectedFace"
              item-key="number"
              @item-selected="setFaceMarker"
              :hide-default-footer="true"
              :items-per-page="-1"
              dense
            >
              <!-- Template für Icons im Header -->
              <template v-slot:header.data-table-select>
                <v-icon small class="ml-1">mdi-eye</v-icon>
              </template>
              <!-- Template für Materialdämpfung -->
              <template v-slot:item.attenuation="{ item }">
                <span>{{
                  materials[selectedMaterialId[item.number]].attenuation
                    .toString()
                    .concat(unit)
                }}</span>
              </template>
              <!-- Template für Reflexionsklasse -->
              <template v-slot:item.class="{ item }">
                <v-select
                  class="mt-2 mb-n4"
                  dense
                  filled
                  :items="selectableMaterials"
                  item-text="class"
                  item-value="value"
                  v-model="selectedMaterialId[item.number]"
                  @change="setMaterialIds"
                >
                  <template v-slot:item="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.class }}</span>
                      </template>
                      <span
                        >Dämpfung: {{ item.attenuation }} dB</span
                      >
                    </v-tooltip>
                  </template>
                </v-select>
              </template>

              <!-- Template für Farbauswahl-Dialog -->
              <template v-slot:item.color="{ item }">
                <!-- Dialog für Farbauswahl -->
                <v-dialog v-model="showColorDialog" max-width="500px">
                  <!-- Karte für Dialog -->
                  <v-card>
                    <!-- Titel -->
                    <v-card-title> Farbe auswählen </v-card-title>
                    <v-divider></v-divider>
                    <!-- Inhalt -->
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col class="d-flex justify-center">
                            <v-color-picker
                              v-model="editedMaterial.color"
                              hide-mode-switch
                            ></v-color-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <!-- Schaltflächen im Dialog -->
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="accent" @click="closeColorDialog">
                        <v-icon left>mdi-close</v-icon>Abbrechen
                      </v-btn>
                      <v-btn color="accent" @click="saveColor">
                        <v-icon left>mdi-content-save</v-icon>Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Button zur Farbauswahl -->
                <v-btn
                  small
                  :color="toHex(item.color)"
                  @click="editColor(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <!-- Slider für Deckkraft -->
            <v-slider
              label="Deckkraft"
              class="pt-4"
              v-model="opacityPercent"
              v-bind:min="opacitySliderDefault.min"
              v-bind:max="opacitySliderDefault.max"
              v-bind:step="opacitySliderDefault.step"
              validate-on-blur
            >
              <template v-slot:prepend>
                <v-icon>mdi-opacity</v-icon>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="opacityPercent"
                  v-bind:min="opacitySliderDefault.min"
                  v-bind:max="opacitySliderDefault.max"
                  v-bind:step="opacitySliderDefault.step"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 70px"
                  suffix=" %"
                ></v-text-field>
              </template>
            </v-slider>
            <v-divider></v-divider>
            <!-- Controls für Schützenpositionen -->
            <v-switch
              label="Schützenpositionen anzeigen"
              v-model="showShooterPositions"
            >
              <template v-slot:prepend>
                <v-icon>mdi-arrow-expand-right</v-icon>
              </template>
            </v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> 3D-Ansicht </v-card-title>
          <v-divider></v-divider>
          <RoomPlot
            :uuid="roomPlotID"
            :geometry="geometry"
            :position="position"
            :showFace="geometry.showFace"
            :showShooterPositions="showShooterPositions"
          />
        </v-card>
      </v-col>
      <!-- Floating Action Button -->
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="primary"
            to="/dashboard"
            bottom
            right
            fixed
            class="mb-8 mr-8"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon x-large>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>Dashboard</span>
      </v-tooltip>
    </v-row>

    <v-snackbar v-model="isAlertOpen" timeout="5000"
      >Funktion noch nicht implementiert.
      <template v-slot:action="{ attrs }">
        <v-btn color="accent" text v-bind="attrs" @click="isAlertOpen = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from "vue";
import RoomPlot from "../components/plots/RoomPlot.vue";
import { mapMutations, mapGetters } from "vuex";
import * as util from "@/lib/util/index.js";

export default {
  name: "Materialien",
  components: {
    RoomPlot,
  },
  data() {
    return {
      geometry: null,
      position: null,
      materials: null,
      facesOfCube: null,

      isAlertOpen: false,
      showColorDialog: false,
      showShooterPositions: false,

      selectedMaterialId: [3, 3, 1, 4, 1, 2],
      selectedFace: [],

      selectableMaterials: [],

      roomPlotID: 3001,

      opacitySliderDefault: {
        min: 0,
        max: 100,
        step: 1,
      },

      editedIndex: null,
      editedMaterial: {
        color: { r: 0, g: 0, b: 0 },
      },
      defaultMaterial: {
        color: { r: 0, g: 0, b: 0 },
      },

      unit: " dB",

      facesTableHeader: [
        {
          text: "Flächenname",
          value: "facename",
          sortable: false,
        },
        {
          text: "Materialdämpfung",
          value: "attenuation",
          sortable: false,
        },
        {
          text: "Reflexionsklasse",
          value: "class",
          sortable: false,
        },
        {
          text: "Farbe",
          align: "center",
          value: "color",
          sortable: false,
        },
      ],

      tableHeaders: [
        {
          text: "Name",
          value: "facename",
          sortable: false,
        },
        {
          text: "Reflexionsklasse",
          value: "classname",
          sortable: false,
        },
        {
          text: "Materialdämpfung",
          align: "center",
          value: "delta",
          sortable: false,
        },
        {
          text: "Farbe",
          align: "center",
          value: "color",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    showColorDialog(val) {
      val || this.closeColorDialog();
    },
  },
  created() {
    this.geometry = this.getGeometry;
    this.position = this.getPosition;
    this.materials = this.getMaterials;
    this.facesOfCube = this.getFacesOfCube;

    this.selectableMaterials = this.selectableMaterialsLoader();

    this.$bus.$on("contextEvent", ($contextPayload) => {
      if ($contextPayload == "showAlert") {
        this.isAlertOpen = true;
      }
    });
  },
  computed: {
    ...mapGetters([
      "getGeometry",
      "getPosition",
      "getMaterials",
      "getFacesOfCube",
    ]),

    opacityPercent: {
      get: function () {
        return Math.trunc(this.geometry.opacity * 100);
      },
      set: function (value) {
        this.geometry.opacity = value / 100;
      },
    },
  },
  methods: {
    ...mapMutations(["setGeometry"]),

    selectableMaterialsLoader() {
      let selectableMaterials = Array(this.materials.length);

      for (let i = 0; i < this.materials.length; i++) {
        selectableMaterials[i] = {
          value: i,
          name: this.materials[i].name,
          attenuation: this.materials[i].attenuation,
          class: this.materials[i].class,
        };
      }
      return selectableMaterials;
    },
    setMaterialIds() {
      for (let i = 0; i < this.facesOfCube.length; i++) {
        this.facesOfCube[i].material_id = this.selectedMaterialId[i];
      }
    },
    setFaceMarker(payload) {
      if (payload.value) {
        this.geometry.showFace = payload.item.number;
      } else {
        this.geometry.showFace = null;
      }
    },
    toHex(color) {
      return util.rgbToHex(color);
    },
    editColor(item) {
      this.editedIndex = this.facesOfCube.indexOf(item);
      this.editedMaterial = Object.assign({}, item);
      this.showColorDialog = true;
    },

    closeColorDialog() {
      this.showColorDialog = false;
      this.$nextTick(() => {
        this.editedMaterial = Object.assign({}, this.defaultMaterial);
      });
    },

    saveColor() {
      Object.assign(this.facesOfCube[this.editedIndex], this.editedMaterial);
      Vue.set(this.geometry.color, this.editedIndex, this.editedMaterial.color);
      this.closeColorDialog();
    },

    emitContextEvent(contextPayload) {
      this.$bus.$emit("contextEvent", contextPayload);
    },
  },
  params: {
    // Legt Titel und Einträge im Kontextmenu fest
    menu: [
      { title: "Material Menu", subtitle: "Subtitle" },
      {
        entry: [
          {
            name: "Materialien zurücksetzen",
            icon: "mdi-restore",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Materialien speichern",
            icon: "mdi-content-save",
            contextHandle: "showAlert",
            divider: false,
          },
          {
            name: "Materialien laden",
            icon: "mdi-cloud-upload",
            contextHandle: "showAlert",
            divider: false,
          },
        ],
      },
    ],
  },
};
</script>

<style lang="postcss">
td {
  width: 50px;
}
</style>