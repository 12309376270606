import jwt from "jsonwebtoken";
import { ApiClient, AuthenticationApi, Credentials } from "../api/src";

var access_token = Object();

var API_URL = "";
var KEY_URL = "";
var PUB_KEY = "";
var JWT_OPTS = {
  algorithms: ["RS256"],
  ignoreNotBefore: true,
};

/**
 * Setzt die URLs des API und des öffentlichen RSA-Schlüssels.
 * @param  {String} key_url URL des öffentlichen RSA-Schlüssels
 * @param  {String} api_url URL des API
 */
export async function init(key_url, api_url) {
  KEY_URL = key_url;
  API_URL = api_url;
  PUB_KEY = await getPublicKey();
}

/**
 * Gibt den öffentlichen RSA-Schlüssel des Backends zurück.
 * @returns {String} Zeichenkette mit öffentlichem RSA-Schlüssel
 */
export async function getPublicKey() {
  let url = KEY_URL;

  let key = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-x509-ca-cert",
      "Access-Control-Allow-Origin": url,
    },
  })
    .then(async function(response) {
      if (!response.ok) {
        throw new Error("HTTP error, status = " + response.status);
      }
      return await response.text();
    })
    .then(function(text) {
      return text;
    })
    .catch((reason) => {
      alert(reason);
    });
  return key;
}

export async function login(user) {
  var defaultClient = ApiClient.instance;
  // eslint-disable-next-line
  // var bearerAuth = defaultClient.authentications["bearerAuth"];

  var api = new AuthenticationApi(defaultClient);
  var opts = {
    credentials: Credentials.constructFromObject({
      email: user.email,
      password: user.password,
    }),
  };
  return new Promise((resolve) => {
    var loginCallback = function(error, data, response) {
      if (error) {
        console.error(error);
        resolve(false);
      } else {
        if (response.statusCode == 200 && response.body.access_token != null) {
          jwt.verify(response.body.access_token, PUB_KEY, JWT_OPTS, function(
            error
          ) {
            if (error) {
              console.error(error);
              resolve(false);
            } else {
              access_token = response.body.access_token;
              // bearerAuth = response.body.access_token;
              resolve(true);
            }
          });
        } else {
          resolve(false);
        }
      }
    };

    api.login(opts, (error, data, response) =>
      loginCallback(error, data, response)
    );
  });
}

/**
 * Meldet Benutzer am Backend an.
 * @param {Object} user Objekt mit Benutzerdaten
 * @returns {Boolean} Wahr im Falle erfolgreicher Anmeldung
 */
export async function _login(user) {
  let url = API_URL + "/login";
  let isAuth = false;

  await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": url,
    },
    body: JSON.stringify(user),
  })
    .then((data) => data.json())
    .then((json) => {
      let response = json;
      try {
        if (response.access_token != null) {
          jwt.verify(response.access_token, PUB_KEY, JWT_OPTS, function(error) {
            if (error) {
              isAuth = false;
            } else {
              access_token = response.access_token;
              isAuth = true;
            }
          });
        } else {
          access_token = {};
          isAuth = false;
        }
      } catch (error) {
        console.log(error);
        isAuth = false;
      }
    })
    .catch((reason) => {
      console.log(reason);
      isAuth = false;
    });

  return isAuth;
}

/**
 * Meldet Benutzer vom Backen ab und löscht den Token.
 * @returns {Boolean} Login Status des Benutzers
 */
export function logout() {
  let isAuth = false;

  access_token = "";

  return isAuth;
}

/**
 * Gibt Beispiel-Daten vom Backend zurück. Funktion kann zu Testzwecken genutzt werden.
 * @returns {JSON} Beispieldaten vom Backend
 */
export async function getSample() {
  let url = API_URL + "/sample";
  let response = String();

  await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": url,
      Authorization: "Bearer " + access_token,
    },
  })
    .then((data) => data.json())
    .then((json) => {
      response = json;
    })
    .catch((reason) => {
      alert(reason);
    });

  return response;
}

/**
 * Gibt den aktuellen Zugangs-Token zurück.
 * @returns {String} Aktueller Zugangs-Token
 */
export function getAccessToken() {
  return access_token;
}

/**
 * Erneuert den aktuellen Zugangs-Token für das Backend.
 * @returns {Boolean} Login Status des Benutzers
 */
export async function refreshToken() {
  let url = API_URL + "/refresh";
  let isAuth = false;

  await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": url,
    },
  })
    .then((data) => data.json())
    .then((json) => {
      let response = json;
      jwt.verify(response.access_token, PUB_KEY, JWT_OPTS, function(error) {
        if (error) {
          isAuth = false;
        } else {
          access_token = response.access_token;
          isAuth = true;
        }
      });
    })
    .catch((reason) => {
      alert(reason);
    });

  return isAuth;
}

/**
 * Gibt die konfigurierte Token-Lebensdauer in Sekunden zurück.
 * @returns {Number} Token-Lebensdauer in Sekunden oder -1 im Fehlerfall
 */
export function getTokenLifeInSeconds() {
  let lifeInSeconds = -1;

  if (access_token != null) {
    try {
      jwt.verify(access_token, PUB_KEY, JWT_OPTS, function(error, decoded) {
        if (error) {
          lifeInSeconds = -1;
        } else {
          lifeInSeconds = decoded.exp - decoded.iat;
        }
      });
    } catch (error) {
      lifeInSeconds = -1;
      alert(error);
    }
  } else {
    lifeInSeconds = -1;
  }

  return lifeInSeconds;
}

/**
 * Startet einen Timer für die automatische Erneuerung des Tokens.
 * @returns {Number} Indenfikationsnummer des Timers
 */
export function enableRefresh() {
  let timerId = 0;
  let marginInSeconds = 5;
  let tokenLifeInSeconds = getTokenLifeInSeconds();

  if (tokenLifeInSeconds > 0) {
    let intervalInMilliseconds = (tokenLifeInSeconds - marginInSeconds) * 1000;
    timerId = window.setInterval(() => {
      refreshToken();
    }, intervalInMilliseconds);
  }

  return timerId;
}

/**
 * Stoppt den Timer mit der Identifikationsnummer timerId.
 * @param  {Number} timerId Indenfikationsnummer des Timers
 */
export function disableRefresh(timerId) {
  clearInterval(timerId);
}
