<template>
  <v-card class="mb-6">
    <v-card-title>
      Qualitätszahlen
      <v-spacer></v-spacer>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showQualityNumbers">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showQualityNumbers = !showQualityNumbers"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showQualityNumbers = !showQualityNumbers"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>

    <v-expand-transition>
      <div v-show="showQualityNumbers" style="position: relative">
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="text-center">
            <v-col
              ><span class="title font-weight-light"
                >Pfander-Kriterium:</span
              ></v-col
            >
            <v-col
              ><span class="title font-weight-light">AHAAH-Modell:</span></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <GaugePlot
                :uuid="this.uuid[0]"
                :qualityNumber="this.qualityNumbers.pfander"
                abbreviation="QP"
              />
            </v-col>
            <v-col>
              <GaugePlot
                :uuid="this.uuid[1]"
                :qualityNumber="this.qualityNumbers.ahaah"
                abbreviation="QA"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-overlay :value="this.showOverlay" :absolute="true" z-index="4"
          ><v-progress-circular indeterminate size="32"></v-progress-circular
        ></v-overlay>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import GaugePlot from "../plots/GaugePlot.vue";

export default {
  props: {
    uuid: Array,
    qualityNumbers: Object,
    showOverlay: Boolean,
  },
  components: {
    GaugePlot,
  },
  data: () => ({
    showQualityNumbers: true,
  }),
};
</script>