/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Geometrie from "../views/Geometrie.vue";
import Materialien from "../views/Materialien.vue";
import Szenario from "../views/Szenario.vue";
import Settings from "../views/Settings.vue";
import Impressum from "../views/Impressum.vue";
import Datenschutz from "../views/Datenschutz.vue";
import store from "../store";

Vue.use(VueRouter);
Vue.use(Vuex);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      reqAuth: true,
    },
  },
  {
    path: "/geometrie",
    name: "Geometrie",
    component: Geometrie,
    meta: {
      reqAuth: true,
    },
  },
  {
    path: "/materialien",
    name: "Materialien",
    component: Materialien,
    meta: {
      reqAuth: true,
    },
  },
  {
    path: "/szenario",
    name: "Szenario",
    component: Szenario,
    meta: {
      reqAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      reqAuth: true,
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: {
      reqAuth: false,
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutzerklärung",
    component: Datenschutz,
    meta: {
      reqAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.reqAuth) &&
    !store.state.user.isAuth
  ) {
    next("/");
  } else {
    next();
  }
});

export default router;
