<template>
  <v-card class="mb-6">
    <v-card-title>
      Raumparameter
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/geometrie" class="mr-5" v-bind="attrs" v-on="on">
            <v-icon>mdi-wrench</v-icon>
          </v-btn>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showGeometry">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showGeometry = !showGeometry"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showGeometry = !showGeometry"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <!-- Ausgabe der Raumparameter -->
    <v-expand-transition>
      <div v-show="showGeometry">
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                :headers="geometryTableHeader"
                :items="[geometry.length]"
                :hide-default-footer="true"
                :items-per-page="-1"
                dense
              >
                <!-- Templates zum Anhängen der Einheit -->
                <template v-slot:item.x="{ item }">
                  {{ item.x.toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.y="{ item }">
                  {{ item.y.toString().concat(unit_meter) }}
                </template>
                <template v-slot:item.z="{ item }">
                  {{ item.z.toString().concat(unit_meter) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    geometry: Object,
  },
  data: () => ({
    showGeometry: true,

    unit_meter: " m",

    geometryTableHeader: [
      {
        text: "Länge x",
        value: "x",
        sortable: false,
      },
      {
        text: "Breite y",
        value: "y",
        sortable: false,
      },
      {
        text: "Höhe z",
        value: "z",
        sortable: false,
      },
    ],
  }),
};
</script>