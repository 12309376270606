import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    geometry: {
      length: {
        x: 50,
        y: 20,
        z: 3,
      },
      camera: {
        x: 3,
        y: 3,
        z: 3,
      },
      color: [
        { r: 50, g: 200, b: 200 },
        { r: 100, g: 200, b: 255 },
        { r: 150, g: 200, b: 115 },
        { r: 200, g: 200, b: 50 },
        { r: 230, g: 200, b: 10 },
        { r: 255, g: 150, b: 0 },
      ],
      opacity: 0.75,
      showFace: null,
      faceColor: "rgb(255,0,255)",
      showShooterPosition: false,
      showReceiver: false,
    },
    materials: [
      // { name: "Schallhartes Material", class: "RK0", attenuation: 0 },
      // { name: "Vinylboden", class: "RK1", attenuation: 1 },
      // { name: "Lüftungsanlage I", class: "RK4", attenuation: 4 },
      // { name: "Lüftungsanlage II", class: "RK5", attenuation: 5 },
      // { name: "Holzwolleplatten", class: "RK2", attenuation: 2 },
      // { name: "Glasgranulatpaneele", class: "RK3", attenuation: 3 },
      // { name: "Akustikpaneele I", class: "RK4", attenuation: 4 },
      // { name: "Akustikpaneele II", class: "RK6", attenuation: 6 },
      // { name: "Akustikschaumstoff I", class: "RK10", attenuation: 10 },
      // { name: "Akustikschaumstoff II", class: "RK15", attenuation: 15 },
      // { name: "Akustikschaumstoff III", class: "RK20", attenuation: 20 },

      { name: "Generisches Material", class: "RK0", attenuation: 0 },
      { name: "Generisches Material", class: "RK1", attenuation: 1 },
      { name: "Generisches Material", class: "RK2", attenuation: 2 },
      { name: "Generisches Material", class: "RK3", attenuation: 3 },
      { name: "Generisches Material", class: "RK4", attenuation: 4 },
      { name: "Generisches Material", class: "RK6", attenuation: 6 },
      { name: "Generisches Material", class: "RK10", attenuation: 10 },
      { name: "Generisches Material", class: "RK15", attenuation: 15 },
      { name: "Generisches Material", class: "RK20", attenuation: 20 },
    ],
    facesOfCube: [
      {
        number: 0,
        facename: "Seitenwand links",
        shortname: "Wl",
        material_id: 5,
        color: { r: 50, g: 200, b: 200 },
      },
      {
        number: 1,
        facename: "Seitenwand rechts",
        shortname: "Wr",
        material_id: 5,
        color: { r: 100, g: 200, b: 255 },
      },
      {
        number: 2,
        facename: "Boden",
        shortname: "B",
        material_id: 1,
        color: { r: 150, g: 200, b: 115 },
      },
      {
        number: 3,
        facename: "Decke",
        shortname: "D",
        material_id: 5,
        color: { r: 200, g: 200, b: 50 },
      },
      {
        number: 4,
        facename: "Geschossfang",
        shortname: "Wz",
        material_id: 0,
        color: { r: 230, g: 200, b: 10 },
      },
      {
        number: 5,
        facename: "Rückwand",
        shortname: "Wh",
        material_id: 1,
        color: { r: 255, g: 150, b: 0 },
      },
    ],
    position: {
      shooter: [
        {
          number: 1,
          name: "PS,ZI-R,w",
          comment: "Position I, rückwärtig, wandseitig",
          x: Number,
          y: Number,
          z: Number,
          selected: true,
        },
        {
          number: 2,
          name: "PS,ZI-R,m",
          comment: "Position I, rückwärtig, mittig",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          number: 3,
          name: "PS,ZII,w",
          comment: "Position II, wandseitig",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          number: 4,
          name: "PS,ZII,m",
          comment: "Position II, mittig",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          number: 5,
          name: "PS,ZI,w",
          comment: "Position I, wandseitig",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          number: 6,
          name: "PS,ZI,m",
          comment: "Position I, mittig",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
      ],
      rating: [
        {
          parent: 1,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: true,
        },
        {
          parent: 1,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 2,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 2,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 3,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 3,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 4,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 4,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 5,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 5,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 6,
          number: 1,
          name: "PB1",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
        {
          parent: 6,
          number: 2,
          name: "PB2",
          x: Number,
          y: Number,
          z: Number,
          selected: false,
        },
      ],
    },
    weapon: [
      {
        number: 1,
        class: "Pistole",
        peaklevel: 174.2,
        radius: 0.38,
        directivity: 18,
        shootingPosition: 0,
        selected: true,
      },
      {
        number: 2,
        class: "Maschinenpistole",
        peaklevel: 174.2,
        radius: 0.38,
        directivity: 16,
        shootingPosition: 0,
        selected: false,
      },
      {
        number: 3,
        class: "Gewehr",
        peaklevel: 178.8,
        radius: 0.52,
        directivity: 11,
        shootingPosition: 0,
        selected: false,
      },
      {
        number: 4,
        class: "Maschinengewehr",
        peaklevel: 179.8,
        radius: 0.56,
        directivity: 11,
        shootingPosition: 0,
        selected: false,
      },
    ],
    user: {
      id: Number(),
      name: String(),
      email: String(),
      password: String(),
      group: String("user"),
      group_id: Number(1000),

      isAuth: Boolean(false),
    },
  },
  getters: {
    getGeometry: (state) => state.geometry,
    getShowSender: (state) => state.geometry.showSender,
    getShowReceiver: (state) => state.geometry.showReceiver,
    getGeometryCamera: (state) => state.geometry.camera,

    getMaterial: (state) => state.facesOfCube,
    getFacesOfCube: (state) => state.facesOfCube,
    getMaterials: (state) => state.materials,
    getPosition: (state) => state.position,
    getWeapon: (state) => state.weapon,

    getUser: (state) => state.user,
  },
});
