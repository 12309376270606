<template>
  <v-card class="mb-6">
    <v-card-title>
      Zeitsignale
      <v-spacer></v-spacer>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showSourceSignal">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showSourceSignal = !showSourceSignal"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showSourceSignal = !showSourceSignal"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <!-- Timeplot für Quellsignal -->
    <v-expand-transition>
      <div v-show="showSourceSignal" style="position: relative">
        <v-divider></v-divider>
        <TimePlot
          :uuid="this.uuid"
          :time="[this.signalTime, this.signalTime]"
          :value="[this.sourceSignalValue, receiverSignalValue]"
        />
        <v-overlay :value="this.showOverlay" :absolute="true" z-index="4"
          ><v-progress-circular indeterminate size="32"></v-progress-circular
        ></v-overlay>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import TimePlot from "../plots/TimePlot.vue";

export default {
  props: {
    uuid: Number,
    signalTime: Array,
    sourceSignalValue: Array,
    receiverSignalValue: Array,
    showOverlay: Boolean,
  },
  components: {
    TimePlot,
  },
  data: () => ({
    showSourceSignal: true,
  }),
};
</script>