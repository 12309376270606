<template>
  <v-card>
    <v-card-title>
      Benutzerverwaltung
      <v-spacer />
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showCard">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="showCard = !showCard" v-bind="attrs" v-on="on">
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="showCard = !showCard" v-bind="attrs" v-on="on">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showCard">
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col class="mt-2">
              <v-icon class="mt-n1">mdi-account</v-icon>
              <span class="ml-3 text-body-1">Benutzer:</span>
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.name"
                label="Name"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n6">
            <v-col></v-col>
            <v-col>
              <v-text-field
                v-model="user.group"
                label="Gruppe"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mt-n4" />
          <v-form @submit.prevent ref="email" v-model="validateEmailReset">
            <v-row class="mt-2">
              <v-col class="mt-2">
                <v-icon class="mt-n1">mdi-email</v-icon>
                <span class="ml-3 text-body-1">E-Mail-Adresse:</span>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="user.mail"
                  label="Aktuelle E-Mail-Adresse"
                  outlined
                  dense
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col></v-col>
              <v-col>
                <v-text-field
                  v-model="newEmail"
                  label="Neue E-Mail-Adresse"
                  outlined
                  dense
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col></v-col>
              <v-col>
                <v-text-field
                  v-model="matchEmail"
                  label="Neue E-Mail-Adresse wiederholen"
                  outlined
                  dense
                  :rules="[matchEmailRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-spacer />
              <v-col cols="3"
                ><v-btn color="primary" @click="resetEmailInput" block>
                  <v-icon left>mdi-close</v-icon>Abbrechen
                </v-btn></v-col
              >
              <v-col cols="3"
                ><v-btn
                  color="primary"
                  type="submit"
                  :disabled="!validateEmailReset"
                  @click="submitEmailInput"
                  block
                >
                  <v-icon left>mdi-content-save</v-icon>Speichern
                </v-btn></v-col
              >
            </v-row>
          </v-form>
          <v-divider class="mt-2" />
          <v-form
            @submit.prevent
            ref="password"
            v-model="validatePasswordReset"
          >
            <v-row class="mt-2">
              <v-col class="mt-2">
                <v-icon class="mt-n1">mdi-lock</v-icon>
                <span class="ml-3 text-body-1">Passwort:</span>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="currentPassword"
                  type="password"
                  label="Aktuelles Passwort"
                  outlined
                  dense
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col></v-col>
              <v-col>
                <v-text-field
                  v-model="newPassword"
                  label="Neues Passwort"
                  outlined
                  dense
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-col></v-col>
              <v-col>
                <v-text-field
                  v-model="matchPassword"
                  label="Neues Passwort wiederholen"
                  outlined
                  dense
                  :rules="[matchPasswordRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n6">
              <v-spacer />
              <v-col cols="3"
                ><v-btn color="primary" block @click="resetPasswordInput">
                  <v-icon left>mdi-close</v-icon>Abbrechen
                </v-btn></v-col
              >
              <v-col cols="3"
                ><v-btn
                  color="primary"
                  type="submit"
                  :disabled="!validatePasswordReset"
                  @click="submitPasswordInput"
                  block
                >
                  <v-icon left>mdi-content-save</v-icon>Speichern
                </v-btn></v-col
              >
            </v-row>
          </v-form>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
const emailLength = 32;
const passwordLengthMin = 8;
const passwordLengthMax = 32;

export default {
  props: {
    user: Object,
  },
  data: () => ({
    showCard: true,

    validateEmailReset: false,
    validatePasswordReset: false,

    currentPassword: String(),
    newPassword: String(),
    matchPassword: String(),

    newEmail: String(),
    matchEmail: String(),

    emailLength: Number(emailLength),
    emailRules: [
      (v) => !!v || "E-Mail darf nicht leer sein.",
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-Mail muss gültiges Format besitzen.",
      (v) =>
        v.length <= emailLength ||
        "E-Mail darf nicht länger als " +
          emailLength.toString() +
          " Zeichen sein.",
    ],
    passwordRules: [
      (v) => !!v || "Passwort darf nicht leer sein.",
      (v) =>
        v.length >= passwordLengthMin ||
        "Passwort darf nicht kürzer als " +
          passwordLengthMin.toString() +
          " Zeichen sein.",
      (v) =>
        v.length <= passwordLengthMax ||
        "E-Mail darf nicht länger als " +
          passwordLengthMax.toString() +
          " Zeichen sein.",
    ],
  }),
  computed: {
    matchEmailRule: {
      get: function () {
        return (
          this.newEmail == this.matchEmail || "Eingaben stimmen nicht überein."
        );
      },
    },
    matchPasswordRule: {
      get: function () {
        return (
          this.newPassword == this.matchPassword ||
          "Eingaben stimmen nicht überein."
        );
      },
    },
  },
  methods: {
    resetEmailInput() {
      this.newEmail = "";
      this.matchEmail = "";
      this.$refs.email.resetValidation();
    },
    submitEmailInput() {
      console.log("Send old and new email to backend.");
      this.resetEmailInput();
    },
    resetPasswordInput() {
      this.currentPassword = "";
      this.newPassword = "";
      this.matchPassword = "";
      this.$refs.password.resetValidation();
    },
    submitPasswordInput() {
      console.log("Send old and new passwords to backend.");
      this.resetPasswordInput();
    },
  },
};
</script>