<template>
  <div>
    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon
        @click="isNavOpen = true"
        v-if="user.isAuth"
      ></v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        @click="isLoginOpen = true"
        v-else
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Chaser Web</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- View Menu -->
      <v-menu
        :offset-y="true"
        :offset-x="false"
        class="px-5"
        v-if="user.isAuth"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-2" v-bind="attrs" v-on="on"
            ><v-icon>mdi-dots-grid</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item-group>
            <template v-for="(view, index) in views">
              <v-divider v-if="view.divider" :key="view.divider"></v-divider>
              <v-list-item
                :key="index"
                :to="{ path: '/' + view.title.toLowerCase() }"
              >
                <v-list-item-icon>
                  <v-icon>{{ view.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ view.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <!-- Login Dialog -->
      <v-dialog v-if="!user.isAuth" v-model="isLoginOpen" max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="accent" class="mx-4" v-bind="attrs" v-on="on"
            ><v-icon left>mdi-account</v-icon>
            Login
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="ml-3">
            <v-icon large class="ml-n1 mr-2">mdi-account</v-icon>
            <span class="headline">Login</span>
          </v-card-title>
          <v-form
            @keyup.enter="submitDialog"
            @submit.prevent
            ref="login"
            v-model="isLoginValid"
          >
            <v-card-text>
              <v-container>
                <v-alert
                  type="info"
                  color="accent"
                  dense
                  class="text-body-2 text-justify"
                  >Die Benutzung der hier angebotenen Webanwendung ist
                  registrierten Benutzern vorbehalten.
                  <br />
                  Bei Fragen zur Registrierung oder Problemen bei der Anmeldung
                  wenden Sie sich bitte
                  <a
                    href="mailto:consult@cervus.de?subject=Chaser%20Web"
                    @click.stop
                    >per E-Mail an uns</a
                  >.</v-alert
                >
                <v-row class="mt-12">
                  <v-col cols="12">
                    <v-text-field
                      label="E-Mail-Adresse"
                      required
                      outlined
                      dense
                      v-model="user.email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Passwort"
                      type="password"
                      v-model="user.password"
                      required
                      outlined
                      dense
                      :rules="passwordRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-model="acceptTerms" :rules="termsRules">
                      <template v-slot:label>
                        <span class="ml-2 body-2 text-justify">
                          Hiermit bestätige ich, dass ich die
                          <a target="_blank" href="/datenschutz" @click.stop
                            >Datenschutzerklärung</a
                          >
                          zur Kenntnis genommen habe und dieser ausdrücklich
                          zustimme.
                        </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mx-2" @click="resetDialog">
                <v-icon left>mdi-close</v-icon>Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                @click="submitDialog"
                :disabled="!isLoginValid"
              >
                <v-icon left>mdi-send</v-icon>Anmelden
              </v-btn>
            </v-card-actions>
          </v-form>
          <v-overlay :absolute="true" v-if="loginError"
            ><v-alert
              v-model="loginError"
              type="error"
              border="left"
              dismissible
              >Fehler beim Loginversuch.</v-alert
            ></v-overlay
          >
        </v-card>
      </v-dialog>

      <v-btn
        v-else
        color="accent"
        class="mr-2"
        @click="
          {
            (isNavOpen = false), logoutFromBackend();
          }
        "
        to="/"
      >
        <v-icon left>mdi-logout-variant</v-icon>
        Logout
      </v-btn>
    </v-app-bar>

    <!-- Contextual Menu -->
    <v-navigation-drawer v-model="isNavOpen" absolute temporary>
      <v-list-item class="px-4">
        <v-list-item-content>
          <v-list-item-title class="title py-2">
            {{ $route.matched[0].components.default.params.menu[0].title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-btn icon @click="isNavOpen = false">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item-group>
          <template
            v-for="item in $route.matched[0].components.default.params.menu[1]
              .entry"
          >
            <v-list-item
              :key="item.name"
              @change="emitContextEvent(item.contextHandle)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="item.divider" :key="item.divider"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as auth from "@/lib/auth/index.js";

const emailLength = 254;
const passwordLengthMin = 8;
const passwordLengthMax = 32;

export default {
  name: "Navbar",
  data: () => ({
    isNavOpen: false,
    isSettingsOpen: false,
    isLoginOpen: false,
    isLoginValid: false,
    loginError: false,
    acceptTerms: false,

    views: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        divider: false,
      },
      {
        title: "Geometrie",
        icon: "mdi-axis-arrow",
        divider: false,
      },
      {
        title: "Materialien",
        icon: "mdi-buffer",
        divider: false,
      },
      {
        title: "Szenario",
        icon: "mdi-calendar-blank-multiple",
        divider: false,
      },
      {
        title: "Settings",
        icon: "mdi-cog",
        divider: true,
      },
    ],
    user: {},
    refreshId: Number,

    emailLength: Number(emailLength),
    emailRules: [
      (v) => !!v || "E-Mail darf nicht leer sein.",
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-Mail muss gültiges Format besitzen.",
      (v) =>
        v.length <= emailLength ||
        "E-Mail darf nicht länger als " +
          emailLength.toString() +
          " Zeichen sein.",
    ],
    passwordRules: [
      (v) => !!v || "Passwort darf nicht leer sein.",
      (v) =>
        v.length >= passwordLengthMin ||
        "Passwort darf nicht kürzer als " +
          passwordLengthMin.toString() +
          " Zeichen sein.",
      (v) =>
        v.length <= passwordLengthMax ||
        "E-Mail darf nicht länger als " +
          passwordLengthMax.toString() +
          " Zeichen sein.",
    ],
    termsRules: [(v) => !!v || "Zustimmung ist erforderlich."],
  }),
  created() {
    let location = document.location;
    let url = location.protocol + "//" + location.hostname;
    if (location.port != "") {
      url += ":" + location.port;
    }
    let api_url = url + "/api";
    let key_url = url + "/.well-known/public.pem";
    auth.init(key_url, api_url);

    this.user = this.getUser;
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    emitContextEvent(contextPayload) {
      this.$bus.$emit("contextEvent", contextPayload);
    },

    redirectToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },

    async submitDialog() {
      this.loginToBackend();
      this.clearUserInput();
      this.$refs.login.resetValidation();
    },
    resetDialog() {
      this.clearUserInput();
      this.isLoginOpen = false;
      this.$refs.login.resetValidation();
    },

    async loginToBackend() {
      this.user.isAuth = await auth.login({
        email: this.user.email,
        password: this.user.password,
      });
      if (this.user.isAuth) {
        this.isLoginOpen = false;
        this.refreshId = auth.enableRefresh();
        this.redirectToDashboard();
      } else {
        this.loginError = true;
      }
    },
    logoutFromBackend() {
      this.user.isAuth = auth.logout();
      auth.disableRefresh(this.refreshId);
    },
    clearUserInput() {
      this.user.mail = "";
      this.user.password = "";
      this.acceptTerms = false;
    },
  },
};
</script>
