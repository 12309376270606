<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-hover v-slot="{ hover }">
        <v-card class="mt-16" elevation="6">
          <v-img
            src="@/assets/cc-waves.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0), rgba(46,64,107,0.75)"
            height="600px"
            width="400px"
          >
            <div class="text-center mb-12">
              <h1
                class="display-1 font-weight-thin"
                style="text-shadow: 2px 2px 4px #000000"
              >
                Chaser Web
              </h1>
              <h4
                class="subheading font-weight-thin"
                style="text-shadow: 2px 2px 4px #000000"
              >
                Version 2021.03.2-alpha
              </h4>
            </div>

            <v-fade-transition>
              <v-overlay v-if="hover" absolute elevation="6">
                <p
                  class="ma-3 text-subtitle-1 text-justify"
                  style="text-shadow: 2px 2px 4px #000000"
                >
                  Chaser ist ein Tool zur akustischen Optimierung von
                  Schießanlagen. Durch Kopplung dreidimensionaler
                  Schallausbreitungsrechnungen mit aktuellen Gehörmodellen,
                  können Anlagen bereits während der Planungsphase auf eine
                  minimale Gehörgefährdung ausgelegt werden.
                  <br />
                  Die Benutzung ist registrierten Nutzern vorbehalten. Bei
                  Fragen oder Problemen zu Chaser wenden Sie sich gerne per
                  E-Mail an uns.
                </p>
              </v-overlay>
            </v-fade-transition>
          </v-img>
        </v-card>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({
    user: {},
  }),
  params: {
    // Legt Titel und Einträge im Kontextmenu fest
    menu: [
      { title: "Chaser Menu", subtitle: "Subtitle" },
      {
        entry: [
          { name: "Neue Session", icon: "mdi-file-plus", divider: false },
          { name: "Session öffnen", icon: "mdi-folder-open", divider: false },
          {
            name: "Session speichern",
            icon: "mdi-content-save",
            divider: false,
          },
          {
            name: "Session herunterladen",
            icon: "mdi-download",
            divider: false,
          },
        ],
      },
    ],
  },
};
</script>
