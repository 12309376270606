var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6"},[_c('v-card-title',[_vm._v(" Raumparameter "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"icon":"","to":"/geometrie"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-wrench")])],1)]}}])},[_c('span',[_vm._v("Bearbeiten")])]),(_vm.showGeometry)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showGeometry = !_vm.showGeometry}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,false,1892329298)},[_c('span',[_vm._v("Minimieren")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showGeometry = !_vm.showGeometry}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('span',[_vm._v("Maximieren")])])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGeometry),expression:"showGeometry"}]},[_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.geometryTableHeader,"items":[_vm.geometry.length],"hide-default-footer":true,"items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.x",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.x.toString().concat(_vm.unit_meter))+" ")]}},{key:"item.y",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.y.toString().concat(_vm.unit_meter))+" ")]}},{key:"item.z",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.z.toString().concat(_vm.unit_meter))+" ")]}}])})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }