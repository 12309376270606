/**
 * @summary Funktionssammlung für das Chaser Web Tool
 * @author Carsten Hudasch <hudasch@cervus.de>
 */


export function getTrianglesOfCube({ x, y, z }) {
  let triangles = Array(12);
  let indices = getEdgeIndicesOfCube();
  let vertices = getVerticesOfCube({ x, y, z });

  for (let index = 0; index < triangles.length; index++) {
    triangles[index] = [
      {
        x: vertices.x[indices.i[index]],
        y: vertices.y[indices.i[index]],
        z: vertices.z[indices.i[index]],
      },
      {
        x: vertices.x[indices.j[index]],
        y: vertices.y[indices.j[index]],
        z: vertices.z[indices.j[index]],
      },
      {
        x: vertices.x[indices.k[index]],
        y: vertices.y[indices.k[index]],
        z: vertices.z[indices.k[index]],
      },
    ];
  }

  return triangles;
}

/**
 * Calculates the 8 vertices of a cube defined by geometry.
 * @param {Object} geometry Side length of the cube
 * @returns {Object} Object of three arrays (x, y, z)
 */
export function getVerticesOfCube({ x, y, z }) {
  let vertices = { x: Array, y: Array, z: Array };
  let range = { x: Number, y: Number, z: Number };

  vertices = getVertexIndicesOfCube();
  range = getRangeOfCube({ x, y, z });

  return scaleVertex(vertices, range);
}

/**
 * Returns the 8 vertex indices of a 1x1x1 cube.
 * @returns {Object} Vertex indices of a cube
 */
export function getVertexIndicesOfCube() {
  let vertices = { x: Array, y: Array, z: Array };

  vertices.x = [0, 0, 1, 1, 0, 0, 1, 1];
  vertices.y = [0, 1, 1, 0, 0, 1, 1, 0];
  vertices.z = [0, 0, 0, 0, 1, 1, 1, 1];

  return vertices;
}

/**
 * Calculates the 12 edges of a trisurf cube defined by geometry.
 * @param {Object} geometry Side length of the cube
 * @returns {Object} Object of three arrays (x, y, z)
 */
export function getEdgesOfCube({ x, y, z }) {
  let edges = { i: Array, j: Array, k: Array };
  let vertices = { x: Array, y: Array, z: Array };
  let triangles = { x: Array(12), y: Array(12), z: Array(12) };

  edges = getEdgeIndicesOfCube();
  vertices = getVerticesOfCube({ x, y, z });

  for (let i = 0; i < edges.i.length; i++) {
    triangles.x[i] = vertices.x[edges.i[i]];
    triangles.y[i] = vertices.y[edges.j[i]];
    triangles.z[i] = vertices.z[edges.k[i]];
  }

  return triangles;
}

/**
 * Returns the 12 edge indices of a 1x1x1 cube.
 * @returns {Object} Edge indices of a cube
 */
export function getEdgeIndicesOfCube() {
  let edges = { i: Array(Number), j: Array(Number), k: Array(Number) };

  edges.i = [7, 0, 0, 0, 4, 4, 2, 6, 4, 0, 3, 7];
  edges.j = [3, 4, 1, 2, 5, 6, 5, 5, 0, 1, 2, 2];
  edges.k = [0, 7, 2, 3, 6, 7, 1, 2, 5, 5, 7, 6];

  return edges;
}

/**
 * Calculates the boundaries of a cube defined by given geometry.
 * @param {Object} geometry Side length of the cube
 * @returns {Object} Object containing the min and max range.
 */
export function getRangeOfCube({ x, y, z }) {
  let range = { x: Number, y: Number, z: Number };

  range.x = [0, x];
  range.y = [-y / 2, y / 2];
  range.z = [0, z];

  return range;
}

/**
 * Returns the 5 edge indices of a quad selected by index.
 * @param {Number} index Specifies the quad those edges are returned
 * @returns {Array} Edge indices of the selected quad
 */
export function getEdgeIndicesOfQuad(index) {
  let edges = { x: Array(5), y: Array(5), z: Array(5) };

  switch (index) {
    case 0: // Seitenwand 1
      edges.x = [1, 1, 0, 0, 1];
      edges.y = [0, 0, 0, 0, 0];
      edges.z = [1, 0, 0, 1, 1];
      break;
    case 1: // Seitenwand 2
      edges.x = [1, 1, 0, 0, 1];
      edges.y = [1, 1, 1, 1, 1];
      edges.z = [1, 0, 0, 1, 1];
      break;
    case 2: // Boden
      edges.x = [0, 0, 1, 1, 0];
      edges.y = [0, 1, 1, 0, 0];
      edges.z = [0, 0, 0, 0, 0];
      break;
    case 3: // Decke
      edges.x = [0, 0, 1, 1, 0];
      edges.y = [0, 1, 1, 0, 0];
      edges.z = [1, 1, 1, 1, 1];
      break;
    case 4: // Geschossfang
      edges.x = [0, 0, 0, 0, 0];
      edges.y = [0, 0, 1, 1, 0];
      edges.z = [1, 0, 0, 1, 1];
      break;
    case 5: // Regie
      edges.x = [1, 1, 1, 1, 1];
      edges.y = [0, 0, 1, 1, 0];
      edges.z = [1, 0, 0, 1, 1];
      break;
    default:
      // Seitenwand 1
      edges.x = [1, 1, 0, 0, 1];
      edges.y = [0, 0, 0, 0, 0];
      edges.z = [1, 0, 0, 1, 1];
      break;
  }

  return edges;
}

/**
 * Scales a vertex to a given range.
 * @param {Object} vertex 
 * @param {Object} range 
 * @returns {Object} The scaled vertex
 */
export function scaleVertex(vertex, range) {
  vertex.x = vertex.x.map(function (index) {
    return range.x[index];
  });
  vertex.y = vertex.y.map(function (index) {
    return range.y[index];
  });
  vertex.z = vertex.z.map(function (index) {
    return range.z[index];
  });
  return vertex;
}
