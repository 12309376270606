<template>
  <div>
    <div :ref="uuid" v-bind="plotData" />
  </div>
</template>

<script>
import Plotly from "plotly.js-dist";
import Colors from "@/lib/colors/index.js";

export default {
  components: {},
  props: {
    uuid: Number,
    qualityNumber: Number,
    abbreviation: String,
  },
  data: () => ({
    isFabOpen: false,

    gaugeDataTemplate: {
      type: "indicator",
      mode: "gauge+number",
      value: 1,
      domain: { x: [0, 1], y: [0, 1] },
      number: {
        font: {
          family: "Roboto, sans-serif",
          size: 26,
        },
        prefix: String,
        valueformat: ".2f",
      },
      gauge: {
        shape: "angular",
        borderwidth: 2,
        bar: {
          color: Colors.blue.p50,
          line: {
            color: Colors.blue.p100,
            width: 1,
          },
        },
        axis: {
          range: [0, 1.2],
          tickvals: [0.1, 0.3, 0.5, 0.7, 0.9, 1.1],
          tickfont: {
            family: "Roboto, sans-serif",
            size: 16,
          },
          tickwidth: 2,
        },
        steps: [
          { range: [0, 0.1], color: Colors.class.f },
          { range: [0.1, 0.3], color: Colors.class.e },
          { range: [0.3, 0.5], color: Colors.class.d },
          { range: [0.5, 0.7], color: Colors.class.c },
          { range: [0.7, 0.9], color: Colors.class.b },
          { range: [0.9, 1.2], color: Colors.class.a },
        ],
      },
    },

    gaugeLayout: {
      height: 150,
      margin: { t: 25, r: 25, l: 25, b: 25 },
      font: {
        family: "Roboto, sans-serif",
      },
    },
  }),
  mounted() {
    Plotly.newPlot(this.$refs[this.uuid], this.plotData, this.gaugeLayout, {
      displayModeBar: false,
    });
  },
  watch: {
    plotData: {
      handler() {
        Plotly.react(this.$refs[this.uuid], this.plotData, this.gaugeLayout);
      },
      deep: true,
    },
  },
  computed: {
    plotData: function () {
      var gaugeData = {};
      Object.assign(gaugeData, this.gaugeDataTemplate);

      gaugeData.number.prefix =
        this.abbreviation[0] + "<sub>" + this.abbreviation[1] + "</sub> = ";

      gaugeData.value = this.qualityNumber;

      return [gaugeData];
    },
  },
};
</script>

<style lang="postcss">
.gauge {
  position: relative;
}
.inner-text {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
</style>