<template>
  <v-card class="mb-6">
    <v-card-title>
      Materialparameter
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/materialien" class="mr-5" v-bind="attrs" v-on="on">
            <v-icon>mdi-wrench</v-icon>
          </v-btn>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <!-- Tooltip Minimieren -->
      <v-tooltip bottom v-if="showMaterial">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showMaterial = !showMaterial"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </template>
        <span>Minimieren</span>
      </v-tooltip>
      <!-- Tooltip Maximieren -->
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="showMaterial = !showMaterial"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Maximieren</span>
      </v-tooltip>
    </v-card-title>
    <!-- Ausgabe der Materialparameter -->
    <v-expand-transition>
      <div v-show="showMaterial">
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="materialTableHeader"
            :items="facesOfCube"
            :hide-default-footer="true"
            :items-per-page="-1"
            dense
          >
            <!-- Template für Materialnamen -->
            <!-- <template v-slot:item.name="{ item }">
              {{ materials[facesOfCube[item.number].material_id].name }}
            </template> -->
            <!-- Template für Materialklasse -->
            <template v-slot:item.class="{ item }">
              {{ materials[facesOfCube[item.number].material_id].class }}
            </template>
            <!-- Template für Materialdämpfung -->
            <template v-slot:item.attenuation="{ item }">
              {{
                materials[facesOfCube[item.number].material_id].attenuation
                  .toString()
                  .concat(unit_decibel)
              }}
            </template>
            <!-- Template für Farbauswahl -->
            <template v-slot:item.color="{ item }">
              <v-btn
                small
                class="disable-events"
                :color="_rgbToHex(item.color)"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { rgbToHex } from "@/lib/util/index.js";

export default {
  props: {
    materials: Array,
    facesOfCube: Array,
  },
  data: () => ({
    showMaterial: true,

    unit_decibel: " dB",

    materialTableHeader: [
      {
        text: "Flächenname",
        value: "facename",
        sortable: false,
        width: "40%",
      },
      // {
      //   text: "Materialname",
      //   value: "name",
      //   sortable: false,
      //   width: "25%",
      // },
      {
        text: "Reflexionsklasse",
        value: "class",
        sortable: false,
        width: "20%"
      },
      {
        text: "Materialdämpfung",
        value: "attenuation",
        sortable: false,
        width: "20%"
      },
      {
        text: "Farbe",
        value: "color",
        sortable: false,
        width: "20%"
      },
    ],
  }),
  methods: {
    _rgbToHex(color) {
      return rgbToHex(color);
    },
  },
};
</script>