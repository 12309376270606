/**
 * Cervus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Material from './Material';
import Point3d from './Point3d';
import Triangle from './Triangle';

/**
 * The ChaserInput model module.
 * @module model/ChaserInput
 * @version 1.0.0
 */
class ChaserInput {
    /**
     * Constructs a new <code>ChaserInput</code>.
     * Input parameters describing a chaser scene
     * @alias module:model/ChaserInput
     * @param dampingGroundReference {Number} 
     * @param materials {Array.<module:model/Material>} 
     * @param receiverPosition {module:model/Point3d} 
     * @param sourcePosition {module:model/Point3d} 
     * @param triangles {Array.<module:model/Triangle>} 
     * @param weaponClass {String} 
     */
    constructor(dampingGroundReference, materials, receiverPosition, sourcePosition, triangles, weaponClass) { 
        
        ChaserInput.initialize(this, dampingGroundReference, materials, receiverPosition, sourcePosition, triangles, weaponClass);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, dampingGroundReference, materials, receiverPosition, sourcePosition, triangles, weaponClass) { 
        obj['damping_ground_reference'] = dampingGroundReference;
        obj['materials'] = materials;
        obj['receiver_position'] = receiverPosition;
        obj['source_position'] = sourcePosition;
        obj['triangles'] = triangles;
        obj['weapon_class'] = weaponClass;
    }

    /**
     * Constructs a <code>ChaserInput</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ChaserInput} obj Optional instance to populate.
     * @return {module:model/ChaserInput} The populated <code>ChaserInput</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ChaserInput();

            if (data.hasOwnProperty('damping_ground_reference')) {
                obj['damping_ground_reference'] = ApiClient.convertToType(data['damping_ground_reference'], 'Number');
            }
            if (data.hasOwnProperty('materials')) {
                obj['materials'] = ApiClient.convertToType(data['materials'], [Material]);
            }
            if (data.hasOwnProperty('receiver_position')) {
                obj['receiver_position'] = Point3d.constructFromObject(data['receiver_position']);
            }
            if (data.hasOwnProperty('source_position')) {
                obj['source_position'] = Point3d.constructFromObject(data['source_position']);
            }
            if (data.hasOwnProperty('triangles')) {
                obj['triangles'] = ApiClient.convertToType(data['triangles'], [Triangle]);
            }
            if (data.hasOwnProperty('weapon_class')) {
                obj['weapon_class'] = ApiClient.convertToType(data['weapon_class'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} damping_ground_reference
 */
ChaserInput.prototype['damping_ground_reference'] = undefined;

/**
 * @member {Array.<module:model/Material>} materials
 */
ChaserInput.prototype['materials'] = undefined;

/**
 * @member {module:model/Point3d} receiver_position
 */
ChaserInput.prototype['receiver_position'] = undefined;

/**
 * @member {module:model/Point3d} source_position
 */
ChaserInput.prototype['source_position'] = undefined;

/**
 * @member {Array.<module:model/Triangle>} triangles
 */
ChaserInput.prototype['triangles'] = undefined;

/**
 * @member {String} weapon_class
 */
ChaserInput.prototype['weapon_class'] = undefined;






export default ChaserInput;

