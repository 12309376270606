<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <v-card class="mb-6">
          <v-card-title>Datenschutzerklärung</v-card-title>
          <v-divider />
          <v-card-text
            ><div class="mt-8 mb-4 subtitle-1">Datenschutz</div>
            <p class="text-justify">
              Die Cervus Consult GmbH nimmt den Schutz Ihrer persönlichen Daten
              sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
              und entsprechend der gesetzlichen Datenschutzvorschriften sowie
              dieser Datenschutzerklärung. Die Nutzung dieser Webanwendung ist
              in der Regel ohne Angabe von personenbezogener Daten möglich. Es
              wird lediglich eine E-Mail-Adresse für den persönlichen Zugang zur
              Webanwendung benötigt. Diese wird ohne Ihre ausdrückliche
              Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
              dass die Datenübertragung im Internet (z. B. bei der Kommunikation
              per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
              Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </p>
            <div class="mt-8 mb-4 subtitle-1">Cookies und lokale Daten</div>
            <p class="text-justify">
              Diese Webanwendung speichert eine eindeutige Kennung in Form eines
              Cookies auf Ihrem Gerät. Diese Kennung dient ausschließlich der
              Authentifizierung der Nutzer gegenüber unseres Servers und ist bis
              zum Ende der Browsersitzung gültig. Daneben werden von der
              Anwendung gegebenenfalls Daten zum Zwecke der Berechnung auf Ihrem
              Endgerät lokal gespeichert. Des weiteren kann es vorkommen, dass
              ihr Browser Daten dieser Webseite, wie beispielsweise Grafiken,
              zwischenspeichert.
            </p>
            <div class="mt-8 mb-4 subtitle-1">Server-Log-Dateien</div>
            <p class="text-justify">
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Files, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
              <ul class="my-2">
                <li>Browsertyp und Browserversion</li>
                <li>Verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
              </ul>
              Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung 
              dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten 
              uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte 
              für eine rechtswidrige Nutzung bekannt werden.
            </p>
          </v-card-text>
          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Floating Action Button -->
    <v-tooltip left v-if="user.isAuth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          color="primary"
          to="/dashboard"
          bottom
          right
          fixed
          class="mb-8 mr-8"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-large>mdi-view-dashboard</v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Datenschutz",
  data: () => ({
    user: {},
  }),
  created() {
    this.user = this.getUser;
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  params: {
    menu: [
      { title: "Datenschutz Menu", subtitle: "Subtitle" },
      {
        entry: [],
      },
    ],
  },
};
</script>