<template>
  <v-app>
    <div>
      <Navbar />

      <v-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </v-main>

      <v-footer absolute>
        <v-spacer></v-spacer>
        <div class="text-caption">
          &copy;
          {{ new Date().getFullYear() }}
          -
          <a href="https://www.cervus.de" target="_blank"
            >Cervus Consult GmbH</a
          >
          -
          <router-link to="impressum">Impressum</router-link>
          -
          <router-link to="datenschutz">Datenschutz</router-link>
        </div>
        <v-spacer></v-spacer>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    apps: [
      { title: "Dashboard", icon: "mdi-view-dashboard" },
      { title: "Geometrie", icon: "mdi-axis-arrow" },
      { title: "Materialien", icon: "mdi-buffer" },
      { title: "Szenario", icon: "mdi-calendar-blank-multiple" },
      { title: "Test", icon: "mdi-calendar-blank-multiple" },
    ],
  }),
};
</script>
